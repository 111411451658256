import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CaSubscriber } from '@ca/ca-utils';
import { StrongPasswordRegx } from '../../config';
import { Store } from '@ngrx/store';
import { ResetPassword } from '../../store/reducers/user.reducer';

@Component({
  selector: 'ca-reset-password',
  template: `
    <ng-container *ngIf="form">
      <div class="form reset-password-form" [formGroup]="form">
        <input hidden type="text" formControlName="code" />
        <mat-form-field>
          <mat-label>Kies een nieuw wachtwoord</mat-label>
          <input formControlName="password" matInput type="password" />
        </mat-form-field>
        {{ passwordFormField?.errors | json }}
        <div class="error" *ngIf="passwordFormField?.dirty">
          <span
            class="block"
            *ngIf="!passwordFormField?.value?.match('^(?=.*[A-Z])')"
            [ngClass]="{ 'text-primary': passwordFormField?.value?.match('^(?=.*[A-Z])') }"
            >Gebruik minstens één hoofdletter.</span
          >
          <span
            class="block"
            *ngIf="!passwordFormField?.value?.match('(?=.*[a-z])')"
            [ngClass]="{ 'text-primary': passwordFormField?.value?.match('(?=.*[a-z])') }"
            >Gebruik minstens één kleine letter.</span
          >
          <span
            class="block"
            *ngIf="!passwordFormField?.value?.match('(.*[0-9].*)')"
            [ngClass]="{ 'text-primary': passwordFormField?.value?.match('(.*[0-9].*)') }"
            >Gebruik minstens één cijfer.</span
          >
          <span
            class="block"
            *ngIf="!passwordFormField?.value?.match('(?=.*[!@#$%^&*])')"
            [ngClass]="{ 'text-primary': passwordFormField?.value?.match('(?=.*[!@#$%^&*])') }"
            >Gebruik minstens één speciaal teken.</span
          >
          <span
            class="block"
            *ngIf="!passwordFormField?.value?.match('.{8,}')"
            [ngClass]="{ 'text-primary': passwordFormField?.value?.match('.{8,}') }"
            >Een wachtwoord moet minstens 8 karakters tellen.</span
          >
        </div>
        <button
          mat-raised-button
          color="primary"
          [disabled]="!this.form.valid"
          (click)="setPassword()"
          class="set-pasword-btn">
          Instellen
        </button>
      </div>
    </ng-container>
  `,

  standalone: false,
})
export class ResetPasswordComponent implements OnDestroy {
  private sub = new CaSubscriber();
  protected code?: string;
  form?: FormGroup;
  get passwordFormField() {
    return this.form?.get('password');
  }
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private store: Store) {
    this.sub.subscribe(this.route.params, {
      next: (p) => {
        this.code = p['code'];
        if (this.code)
          this.form = this.fb.group({
            code: this.fb.control<string>(this.code, Validators.required),
            password: this.fb.control<string | null>(null, [
              Validators.required,
              Validators.pattern(StrongPasswordRegx),
            ]),
          });
      },
    });
  }
  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }
  setPassword() {
    if (this.form) {
      const { code, password } = this.form.getRawValue();
      if (code && password && this.form?.valid)
        this.store.dispatch(
          ResetPassword({
            code,
            password,
          })
        );
    }
  }
}
