<ng-container *ngIf="detail$ | async as detail">
  <div class="header">
    <h1>{{ detail.title }}</h1>
    <ca-ticket-status-chip [ticket_id]="detail.id" [status]="detail.status"></ca-ticket-status-chip>
  </div>

  <div class="props">
    <div class="prop">
      <span class="name">Datum van aanvraag</span>
      <span class="value">{{ detail.requested_at | date }}</span>
    </div>
    <!-- TODO: sub_category -->
    <div class="prop">
      <span class="name">Status</span>
      <span class="value">
        <ca-ticket-status-chip
          [ticket_id]="detail.id"
          [status]="detail.status"></ca-ticket-status-chip>
      </span>
    </div>
    <div class="prop" *ngIf="detail.messages.length > 0">
      <span class="name">Mijn vraag</span>
      <span class="value">{{ detail.messages[0].message }}</span>
    </div>
    <div class="prop" *ngIf="detail.attachments.length > 0">
      <span class="name">Bijlagen</span>
      <span class="value" *ngFor="let att of detail.attachments">{{ att.file_name }}</span>
    </div>
  </div>

  <ca-vns-chat
    *ngIf="user"
    [context]="{
      ticket_id: detail.id,
      user: { id: user.id, role: user.role, name: user.first_name }
    }"></ca-vns-chat>
</ng-container>
