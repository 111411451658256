import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  SendAttachment,
  SendAttachmentFailure,
  SendAttachmentSuccess,
} from '../actions/message.actions';
import { firstValueFrom, switchMap } from 'rxjs';
import { TicketsService } from '../../services/tickets.service';

@Injectable()
export class FilesEffects {
  constructor(private actions$: Actions, private tickets: TicketsService) {}

  uploadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SendAttachment),
      switchMap(({ context, formData }) => {
        console.log('uploading', context, formData);
        return firstValueFrom(this.tickets.uploadFile(context.ticket_id, formData))
          .then((res) => SendAttachmentSuccess({ context, res }))
          .catch((err) => SendAttachmentFailure({ context, formData, error: err }));
      })
    )
  );
}
