import { Route } from '@angular/router';
import { CMSGeneral, PageID } from '@ca/vns-models';
import { PublicPageComponent } from './pages/public-page/public-page.component';
import { NewsArticleComponent } from './pages/news-article/news-article.component';
import { AuthGuardService } from '@ca/vns-ng-core';
import { MyFileComponent } from './pages/my-file/my-file.component';
import { TicketDetailComponent } from './pages/ticket-detail/ticket-detail.component';
import { MijnVnsComponent } from './components/mijn-vns/mijn-vns.component';

export function ConfigureRoutes(pages: CMSGeneral['pages']) {
  const routes: Route[] = [];
  pages.forEach((page) => {
    if (page.routeParam === false) {
      const route: Route = {
        ...GetComponentForPage(page.id),
        path: page.metaUrl,
        title: page.metaTitle,
        data: {
          animation: page.id,
          breadcrumb: PageID.Home === page.id ? undefined : page.metaTitle,
        },
        canActivate: page.membersOnly ? [AuthGuardService] : undefined,
      };
      routes.push(route);
    } else {
      const rootComponent = GetComponentForPage(page.id);
      const root: Route = {
        path: page.metaUrl,
        title: page.metaTitle,
        data: {
          breadcrumb: page.metaTitle,
          animation: page.id, //'MainPage',
        },
        canActivate: page.membersOnly ? [AuthGuardService] : undefined,
      };

      const data = GetComponentForSubPage(page.id);
      if (data) {
        const child: Route = {
          ...data,
          path: ':metaUrl',
          title: page.metaTitle,
          data: {
            animation: 'SubPage',
          },
          canActivate: page.membersOnly ? [AuthGuardService] : undefined,
        };
        const rootSub: Route = {
          ...rootComponent,
          data: {
            breadcrumb: undefined,
            animation: page.id, //'MainPage',
          },
          canActivate: page.membersOnly ? [AuthGuardService] : undefined,
          path: '',
          pathMatch: 'full',
        };
        console.log(child.path, root.path);
        root.children = [child, rootSub];
        routes.push(root);
        if (page.id === PageID.MijnDossier) {
          routes.push({
            path: 'dossiers/:id',
            redirectTo: `${page.metaUrl}/:id`,
          });
        }
      } else throw new Error(`NO SUB PAGE CONFIG FOR PageID: ${page.id} (${page.metaTitle})`);
    }
  });
  // console.log('dynamic routing configuration', routes);
  return routes;
}

function GetComponentForSubPage(pageID: PageID): Pick<Route, 'canActivate' | 'component'> | void {
  switch (pageID) {
    case PageID.Nieuws:
      return { component: NewsArticleComponent };

    case PageID.MijnDossier:
      return { component: TicketDetailComponent };
    default:
      break;
  }
}

function GetComponentForPage(pageID: PageID): Pick<Route, 'canActivate' | 'component'> {
  switch (pageID) {
    case PageID.Home:
    case PageID.OverVNS:
    case PageID.OnzeDiensten:
    case PageID.Nieuws:
    case PageID.LidWorden:
    case PageID.OnzePartners:
    case PageID.Contact:
      // case PageID.PrivacyAndCookiePolicy:
      return { component: PublicPageComponent };

    // members only
    case PageID.MijnDossier:
      return { component: MyFileComponent };

    case PageID.MijnVNS:
      return { component: MijnVnsComponent };

    case PageID.AdviserendeRol:
    case PageID.JuridischeDiensten:
    case PageID.Ondersteuning:
    case PageID.PrivacyAndCookiePolicy:
      // TODO: add canActivate: authGuard (or in ConfigureRoutes using guestOnly/membersOnly)
      return { component: PublicPageComponent };

    default:
      console.error('Unknown PageID: ' + pageID);
      return { component: PublicPageComponent };
  }
}
