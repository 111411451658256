import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  LoginRequestDTO,
  SignInResultDTO,
  UserDTO,
  UserDTOBase,
  VNSEnvironment,
} from '@ca/vns-models';
import { Store } from '@ngrx/store';

import { firstValueFrom, Observable } from 'rxjs';
import { CaSubscriber } from '@ca/ca-utils';
import { ClearNotifications } from '@ca/ca-ng-core';
import { SnackbarService } from '@ca/ca-snackbar';
import { LoadUserProfileSuccess, RefreshToken } from '../store/reducers/user.reducer';
import * as moment from 'moment';
import { VNS_ENVIRONMENT } from '../config';
import { VNSCoreConfig } from '../models/config.models';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  sub: CaSubscriber = new CaSubscriber();

  private checkTokenSchedule?: ReturnType<typeof setTimeout>;

  private get tokenStoreKey() {
    return this.env.sessionStorageBearerKey;
  }
  private refreshTokenStoreKey = 'caRefreshToken';

  get hasBearerToken(): boolean {
    const token = this.bearerToken;
    return token !== undefined && token !== '' && token !== null;
  }

  get bearerToken() {
    return sessionStorage.getItem(this.tokenStoreKey);
  }

  constructor(
    @Inject(VNS_ENVIRONMENT) private env: VNSEnvironment,
    @Inject(VNSCoreConfig) private config: VNSCoreConfig,
    private http: HttpClient,
    private snackbar: SnackbarService,
    private router: Router,
    private store: Store
  ) {
    if (this.hasBearerToken) {
      this.userProfile().then(({ user, refresh_token }) => {
        console.log('user profile fetched');

        if (user !== null) {
          this.store.dispatch(
            LoadUserProfileSuccess({
              user,
              access_token: this.bearerToken as string,
              refresh_token,
            })
          );
        }
      });
    } else console.log('no bearer token');
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
    this.clearTokenSchedule();
  }

  setAccessToken(token: string): void {
    sessionStorage.setItem(this.tokenStoreKey, token);
  }

  setRefreshToken(token: string) {
    sessionStorage.setItem(this.refreshTokenStoreKey, token);
    this.clearTokenSchedule();
    this.scheduleTokenRefresh();
  }

  login(loginDTO: LoginRequestDTO): Observable<SignInResultDTO> {
    const url = `${this.env.backendUrl}/api/auth/${this.config.role}.login`;
    return this.http.post<SignInResultDTO>(url, loginDTO);
  }

  /**
   * Validates the access token found in local storage using the auth API.
   * @returns a boolean indicating wether the
   */
  async validateAccessToken(): Promise<boolean> {
    // check if their is a bearer token
    if (this.bearerToken === null) {
      return false;
    }

    // check if the token is valid
    const isTokenValid = await firstValueFrom(this.heartbeatApiCall())
      .then((res: boolean) => {
        console.log('checking heartbeat', moment().format('HH:mm'));
        if (this.bearerToken !== null && !res) this.snackbar.errorSnackbar('Uw sessie is verlopen');
        return res;
      })
      .catch((err) => {
        console.error(err);
        this.snackbar.errorSnackbar('Uw sessie is verlopen');
        return false;
      });
    return isTokenValid;
  }

  heartbeatApiCall() {
    return this.http.get<boolean>(`${this.env.backendUrl}/api/auth/heartbeat`, {
      headers: this.getAuthHeader(),
    });
  }

  userProfile(): Promise<{ user: UserDTO; refresh_token: string }> {
    return firstValueFrom(
      this.http.get<{ user: UserDTO; refresh_token: string }>(
        `${this.env.backendUrl}/api/auth/me`,
        {
          headers: this.getAuthHeader(),
        }
      )
    );
  }

  // refreshAccessToken() {}

  getAuthHeader(): HttpHeaders {
    return this.bearerToken
      ? getBearerHeaderFromLocalStorage(this.tokenStoreKey)
      : new HttpHeaders();
  }

  getRefreshHeader(): HttpHeaders {
    return this.bearerToken
      ? getBearerHeaderFromLocalStorage(this.refreshTokenStoreKey)
      : new HttpHeaders();
  }

  logoutRequest(): Promise<boolean> {
    console.log('logout as ' + this.config.role, this.getAuthHeader());
    return firstValueFrom(
      this.http.get<boolean>(`${this.env.backendUrl}/api/auth/${this.config.role}.logout`, {
        headers: this.getAuthHeader(),
      })
    );
  }

  refreshToken() {
    return firstValueFrom(
      this.http.get<{ access_token: string; refresh_token: string }>(
        `${this.env.backendUrl}/api/auth/${this.config.role}.refresh`,
        {
          headers: this.getRefreshHeader(),
        }
      )
    );
  }

  _logout() {
    this.store.dispatch(ClearNotifications());
    this.endSession();
    this.clearTokenSchedule();
    this.router.navigate(['/login']);
  }

  private endSession(): void {
    sessionStorage.clear();
  }

  //#region reset password
  resetPassword(email: string) {
    return firstValueFrom(
      this.http.post(`${this.env.backendUrl}/api/auth/${this.config.role}.resetPassword`, {
        email,
      })
    );
  }

  setPassword(token: string, password: string) {
    return firstValueFrom(
      this.http.post(`${this.env.backendUrl}/api/auth/${this.config.role}.setPassword`, {
        token,
        password,
      })
    );
  }
  //#endregion

  //#region Token Schedule for refresh
  private refreshIntervalMS = 60000 * 15;
  scheduleTokenRefresh(): void {
    console.log('scheduling token check');
    this.checkTokenSchedule = setInterval(
      () => this.store.dispatch(RefreshToken()),
      this.refreshIntervalMS
    );
  }

  clearTokenSchedule(): void {
    console.log('clearing schedule for token check');
    if (this.checkTokenSchedule != undefined) clearInterval(this.checkTokenSchedule);
  }
  //#endregion
}

export function getBearerHeaderFromLocalStorage(key: string): HttpHeaders {
  return getBearerHeader(`${sessionStorage.getItem(key)}`);
}

export function getBearerHeader(token: string): HttpHeaders {
  const authHeader = new HttpHeaders({
    Authorization: `Bearer ${token}`,
  });
  return authHeader;
}
