import { Message } from '@ca/vns-models';
import { createReducer, on } from '@ngrx/store';
import { LoadTicketDetailSuccess } from '../actions/tickets.actions';
import { NewMessageReceived } from '../actions/message.actions';

export interface MessagesState {
  [ticket_id: string]: Message[];
}

const initialState: MessagesState = {};

export const reducer = createReducer(
  initialState,
  on(LoadTicketDetailSuccess, (_, { ticket }) => ({
    ..._,
    [ticket.id]: ticket.messages,
  })),
  on(NewMessageReceived, (_, { message }) => ({
    ..._,
    [message.ticket_id]: _[message.ticket_id] ? [..._[message.ticket_id], message] : [message],
  }))
);
