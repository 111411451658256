import { AssignJuristWsEvent, Message, SendMessageWsEvent } from './back-office.models';
import { CreateResultDTO } from './my-vns.models';
import { TicketStatus } from './ticket.models';

export type MessageID = string;

// export interface Message {
//   id: MessageID;
//   ticket_id: string;
//   message: string;
//   sent_by_name: string;
//   sent_by_id: string;
//   sent_by_type: 'member' | 'jurist' | 'admin' | 'third-party';
//   /**
//    * Is this message meant for third party chat?
//    * true => V-NS <-> third-party
//    * false => V-NS <-> member
//    * @default false
//    */
//   third_party: boolean;
// }

interface Error {
  error: string;
  errorDetails?: {
    message: string;
    path: Array<string | number>;
    type: string;
  }[];
}

interface Success<T> {
  data: T;
}

export type Response<T> = Error | Success<T>;

export type SocketEvent<T> = { type: string; payload: T };

export type TicketStatusChangedSocketEventPayload = { id: string; status: TicketStatus };
export const TICKET_STATUS_CHANGED_TYPE = 'ticket.statusChanged' as const;
export type TICKET_STATUS_CHANGED = typeof TICKET_STATUS_CHANGED_TYPE;
export type TicketStatusChangedSocketEvent = {
  type: TICKET_STATUS_CHANGED;
  payload: TicketStatusChangedSocketEventPayload;
};

export const TICKET_CREATED_TYPE = 'ticket.created' as const;
export type TICKET_CREATED = typeof TICKET_CREATED_TYPE;
export type TicketCreatedSocketEvent = { type: TICKET_CREATED; payload: CreateResultDTO };

export const NEW_MESSAGE_TYPE = 'message.new' as const;
export type NEW_MESSAGE = typeof NEW_MESSAGE_TYPE;
export type NewMessageSocketEvent = { type: NEW_MESSAGE; payload: Message };

export const ASSIGNED_JURIST_TYPE = 'ticket.assignedJurist' as const;
export type ASSIGNED_JURIST = typeof ASSIGNED_JURIST_TYPE;
export type JuristAssignedSocketEvent = {
  type: ASSIGNED_JURIST;
  payload: AssignJuristWsEvent;
};

export type SocketEventType =
  | TicketCreatedSocketEvent
  | NewMessageSocketEvent
  | JuristAssignedSocketEvent
  | TicketStatusChangedSocketEvent;
