import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { TicketStatus } from '@ca/vns-models';
import { Store } from '@ngrx/store';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SetTicketStatus } from '../../store/actions/tickets.actions';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'ca-set-status-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatFormFieldModule, MatButtonModule, MatSelectModule],
  templateUrl: './set-status-dialog.component.html',
  styleUrl: './set-status-dialog.component.scss',
})
export class SetStatusDialogComponent implements OnInit {
  status: TicketStatus = 'Open';
  // TODO: role-based bepaalde statussen toelaten
  constructor(@Inject(MAT_DIALOG_DATA) protected data: SetStatusDialogData, private store: Store) {}

  ngOnInit(): void {
    this.status = this.data.currentValue;
  }
  updateStatus() {
    this.store.dispatch(SetTicketStatus({ id: this.data.ticket_id, status: this.status }));
  }
}
export interface SetStatusDialogData {
  ticket_id: string;
  currentValue: TicketStatus;
}
