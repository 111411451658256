import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ContactFormPlugin } from '@ca/vns-models';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'ca-contact-form',
  template: `<form [formGroup]="contactForm">
    <h2 *ngIf="plugin.pluginTitle">{{ plugin.pluginTitle }}</h2>
    <div *ngIf="plugin.text" [innerHTML]="plugin.text"></div>

    <mat-form-field>
      <mat-label>Voornaam</mat-label>
      <input matInput formControlName="first_name" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Achternaam</mat-label>
      <input matInput formControlName="last_name" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>E-Mail</mat-label>
      <input matInput formControlName="email" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Bericht</mat-label>
      <textarea matInput formControlName="message"></textarea>
    </mat-form-field>

    <button (click)="send()">Versturen</button>
  </form>`,
})
export class ContactFormComponent implements OnInit, OnDestroy {
  @Input() plugin!: ContactFormPlugin;
  // TODO: save contactFormResult item in store based on success or failure + clear it when clear contact form action is dispatched
  contactForm = this.fb.group({
    first_name: this.fb.control<string | null>(null, Validators.required),
    last_name: this.fb.control<string | null>(null, Validators.required),
    email: this.fb.control<string | null>(null, [Validators.required, Validators.email]),
    message: this.fb.control<string | null>(null, Validators.required),
    // recaptchaReactive: this.fb.control<string | null>(null, Validators.required),
  });
  private sub?: Subscription;

  constructor(
    private fb: FormBuilder,
    private recaptchaV3Service: ReCaptchaV3Service,
    private analytics: AnalyticsService
  ) {}
  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  ngOnInit(): void {
    if (!this.plugin) throw new Error('ContactFormPlugin not provided!');
  }

  send() {
    // console.log('sending');

    if (this.contactForm.valid === false) {
      console.log(
        'invalid',
        this.contactForm.valid,
        this.contactForm.errors,
        this.contactForm.value
      );
      this.contactForm.controls.email.markAsTouched();
      this.contactForm.controls.first_name.markAsTouched();
      this.contactForm.controls.last_name.markAsTouched();
      this.contactForm.controls.message.markAsTouched();
      return;
    }
    // console.log('validating captcha');

    this.analytics.trackEvent(
      'Contact form submitted',
      'contact sent a message using the contact form in the website',
      'form submit'
    );

    this.sub = this.recaptchaV3Service.execute('submitContactForm').subscribe({
      next: (token: string) => {
        console.log(`Token [${token}] generated`);
        const v = { ...this.contactForm.value };
        console.log('sending...', v);

        this.contactForm.reset();
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
        console.log('Completed');
      },
    });
  }
}
