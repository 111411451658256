import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { RequestNewPassword } from '../../store/reducers/user.reducer';

@Component({
  selector: 'ca-forgot-password',
  template: `
    <div class="form forgot-password-form" [formGroup]="form">
      <div class="warning">
        <p>
          Voeg het e-mail adres in dat gekoppeld is aan je account. Er wordt een e-mail verstuurd
          met een knop om een nieuw wachtwoord in te stellen.
        </p>

        <p class="warning-message">Let op: deze is enkel vandaag geldig.</p>
      </div>
      <p>
        <mat-form-field>
          <mat-label>E-Mail</mat-label>
          <input type="text" matInput formControlName="email" />
        </mat-form-field>
      </p>
      <div class="actions">
        <button
          mat-raised-button
          color="primary"
          [disabled]="!this.form.valid"
          (click)="requestNewPassword()"
          class="request-new-password-btn">
          Versturen
        </button>
      </div>
    </div>
  `,
  standalone: false,
})
export class ForgotPasswordComponent {
  constructor(private fb: FormBuilder, private store: Store) {}
  form = this.fb.group({
    email: this.fb.control<string>('', [Validators.required, Validators.email]),
  });
  requestNewPassword() {
    const { email } = this.form.getRawValue();
    if (this.form.valid && email) this.store.dispatch(RequestNewPassword({ email }));
  }
}
