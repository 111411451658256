import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  CreateResultDTO,
  Message,
  Ticket,
  TicketListItemDTO,
  VNSEnvironment,
} from '@ca/vns-models';
import { VNS_ENVIRONMENT } from '../config';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class TicketsService {
  constructor(
    @Inject(VNS_ENVIRONMENT) private env: VNSEnvironment,
    private http: HttpClient,
    private readonly auth: AuthService
  ) {}

  createTicket(title: string, message: string) {
    return this.http.post<CreateResultDTO>(
      `${this.env.backendUrl}/api/tickets`,
      { title, message },
      {
        headers: this.auth.getAuthHeader(),
      }
    );
  }

  getTickets() {
    return this.http.get<TicketListItemDTO[]>(`${this.env.backendUrl}/api/tickets`, {
      headers: this.auth.getAuthHeader(),
    });
  }

  getTicketDetail(id_or_metaUrl: string) {
    return this.http.get<Ticket>(`${this.env.backendUrl}/api/tickets/${id_or_metaUrl}`, {
      headers: this.auth.getAuthHeader(),
    });
  }

  getMessagesForTicket(id: string) {
    return this.http.get<Message[]>(`${this.env.backendUrl}/api/tickets/${id}/messages`, {
      headers: this.auth.getAuthHeader(),
    });
  }

  uploadFile(ticket_id: string, formData: FormData) {
    return this.http.post<UploadFileResult>(
      `${this.env.backendUrl}/api/tickets/${ticket_id}/files`,
      formData,
      {
        headers: this.auth.getAuthHeader(),
      }
    );
  }
}
export type UploadFileResult = { file: any; createRes: any };
