import {
  LoginRequestDTO,
  RegisterDTO,
  RegisterResultDTO,
  SignInResultDTO,
  UserDTO,
} from '@ca/vns-models';
import { createAction, createReducer, on, props } from '@ngrx/store';

// TODO: make generic or create separate actions for admin/member
export interface UserState {
  isLoggedIn: boolean;
  user?: UserDTO; // AdminSignInResultDTO['admin'] | MemberSignInResultDTO['member'];
  jwt?: SignInResultDTO['access_token'] | SignInResultDTO['access_token'];
  refreshToken?: SignInResultDTO['refresh_token'] | SignInResultDTO['refresh_token'];
  error?: {
    message: string;
    html?: string;
  };
}

export const initialState: UserState = {
  isLoggedIn: false,
};

export enum UserActions {
  register = '[Member] Register',
  registerSucces = '[Member] Register successfully',
  registerFailure = '[Member] Failed to register',

  login = '[User] Login',
  loginAdminSuccess = '[User] Logged in (Admin)',
  loginMemberSuccess = '[User] Logged in (Member)',
  loginFailure = '[User] Login failed',

  refreshToken = '[User] refresh token',
  refreshTokenSuccess = '[User] refreshed token successfully',
  refreshTokenFailure = '[User] failed torefresh token',

  logout = '[User] Logout',
  logoutSuccess = '[User] Logout Success',
  logoutFailure = '[User] Failed to log out',

  sessionExpired = '[User] session expired',
  unauthorizedPageLoad = '[User] Unauthorized Page Load',
  unauthorizedAPIResult = '[User] Unauthorized API result',
  forbiddenHttpResult = '[User] Forbidden API request',

  requestNewPassword = '[User] Request new password',
  requestNewPasswordSuccess = '[User] Successfully requested new password',
  requestNewPasswordFailure = '[User] Failed to request new password',

  resetPassword = '[User] Reset password',
  resetPasswordSuccess = '[User] Reset password successfully',
  resetPasswordFailure = '[User] Failed to reset password',

  loadUserProfile = '[User] load profile',
  loadUserProfileSuccess = '[User] loaded profile',
  loadUserProfileFailure = '[User] failed to load profile',
}

export const LoadUserProfile = createAction(UserActions.loadUserProfile);
export const LoadUserProfileSuccess = createAction(
  UserActions.loadUserProfileSuccess,
  props<{ user: UserDTO; access_token: string; refresh_token: string }>()
);
export const LoadUserProfileFailure = createAction(
  UserActions.loadUserProfileFailure,
  props<{ error: any }>()
);

export const Register = createAction(UserActions.register, props<RegisterDTO>());
export const RegisterSuccess = createAction(
  UserActions.registerSucces,
  props<{ req: Omit<RegisterDTO, 'password'>; res: RegisterResultDTO }>()
);
export const RegisterFailure = createAction(UserActions.registerFailure, props<{ error: any }>());

export const Login = createAction(UserActions.login, props<LoginRequestDTO>());
export const LoginAdminSuccess = createAction(
  UserActions.loginAdminSuccess,
  props<SignInResultDTO>()
);
export const LoginMemberSuccess = createAction(
  UserActions.loginMemberSuccess,
  props<SignInResultDTO>()
);
export const LoginFailure = createAction(UserActions.loginFailure, props<{ error: any }>());

export const Logout = createAction(UserActions.logout);
export const LogoutSuccess = createAction(UserActions.logoutSuccess);
export const LogoutFailure = createAction(UserActions.logoutFailure, props<{ error: any }>());

export const RefreshToken = createAction(UserActions.refreshToken);
export const RefreshTokenSuccess = createAction(
  UserActions.refreshTokenSuccess,
  props<{ access_token: string; refresh_token: string }>()
);
export const RefreshTokenFailure = createAction(
  UserActions.refreshTokenFailure,
  props<{ error: any }>()
);

export const UnauthorizedPageLoad = createAction(
  UserActions.unauthorizedPageLoad,
  props<{
    url: string;
  }>()
);
export const UnauthorizedHttpRequest = createAction(
  UserActions.unauthorizedAPIResult,
  props<{
    url: string;
    error: any;
  }>()
);
export const ForbiddenHttpRequest = createAction(
  UserActions.forbiddenHttpResult,
  props<{
    url: string;
    error: any;
    message: string;
    html?: string;
  }>()
);
export const SessionExpired = createAction(UserActions.sessionExpired);

export const RequestNewPassword = createAction(
  UserActions.requestNewPassword,
  props<{ email: string }>()
);
export const RequestNewPasswordSuccess = createAction(UserActions.requestNewPasswordSuccess);
export const RequestNewPasswordFailure = createAction(
  UserActions.requestNewPasswordFailure,
  props<{ error: any }>()
);

export const ResetPassword = createAction(
  UserActions.resetPassword,
  props<{
    code: string;
    password: string;
  }>()
);
export const ResetPasswordSuccess = createAction(UserActions.resetPasswordSuccess);
export const ResetPasswordFailure = createAction(
  UserActions.resetPasswordFailure,
  props<{ error: any }>()
);
export const reducer = createReducer(
  initialState,
  on(LoginAdminSuccess, (_, { user, access_token, refresh_token }) => ({
    user,
    jwt: access_token,
    refreshToken: refresh_token,
    isLoggedIn: true,
  })),
  on(LoginMemberSuccess, (_, { user: member, access_token, refresh_token }) => ({
    user: member,
    jwt: access_token,
    refreshToken: refresh_token,
    isLoggedIn: true,
  })),
  on(RefreshTokenSuccess, (_, { access_token, refresh_token }) => ({
    ..._,
    jwt: access_token,
    refreshToken: refresh_token,
    isLoggedIn: true,
  })),
  on(ForbiddenHttpRequest, (_, { message, html }) => ({
    ..._,
    error: { message, html },
  })),
  on(LoadUserProfileSuccess, (_, { access_token, refresh_token, user }) => ({
    ..._,
    jwt: access_token,
    refreshToken: refresh_token,
    user,
    isLoggedIn: true,
  })),
  on(LogoutSuccess, () => initialState),
  on(SessionExpired, () => initialState),
  on(UnauthorizedPageLoad, () => initialState),
  on(UnauthorizedHttpRequest, () => initialState),
  on(RefreshTokenFailure, () => initialState)
);
