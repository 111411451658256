import { createReducer, on } from '@ngrx/store';
import {
  UnauthorizedHttpRequest,
  UnauthorizedPageLoad,
  loadUserSuccess,
  loginFailure,
  logout,
  submitLoginForm,
  submitLoginFormFailure,
  submitLoginFormSuccess,
  togglePasswordVisibility,
  twoFaRequest,
} from '../actions';
import { OnboardingState, LoginState } from '../../types/interfaces';

export const initialState: LoginState = {
  hidePassword: true,
};

export const selectLogin = (state: OnboardingState): LoginState => state.login;

export const reducer = createReducer(
  initialState,
  on(togglePasswordVisibility, (_, { hide }) => ({
    ..._,
    hidePassword: hide,
  })),
  on(submitLoginForm, (_) => _),
  on(submitLoginFormSuccess, (_) => _),
  on(submitLoginFormFailure, (_, error) => ({ ..._, error: error })),
  on(loginFailure, (_, error) => ({ ..._, error: error })),
  on(twoFaRequest, (_) => _),
  on(loadUserSuccess, (_, { refreshToken, token }) => ({
    ..._,
    token,
    refreshToken,
  })),
  on(logout, (_) => initialState),
  on(UnauthorizedPageLoad, (_) => initialState),
  on(UnauthorizedHttpRequest, (_) => initialState)
);
