import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { CMSGeneral, Page, PageID, PageInfo } from '@ca/vns-models';
import { filter, firstValueFrom, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { NavigationEnd, Router } from '@angular/router';
import { LoadPagesInfoSuccess } from '../store/reducers/app.reducer';
import { NewTicketFormComponent } from '../components/new-ticket-form/new-ticket-form.component';
import { ConfigureRoutes } from '../config';
import { LoadPage, PagesState } from '../store/reducers/pages.reducer';
import { PageNotFoundComponent } from '../pages/page-not-found/page-not-found.component';
import { AuthGuardService, AuthService, LoginComponent } from '@ca/vns-ng-core';
import { DOCUMENT } from '@angular/common';
import { ResetPasswordComponent, ForgotPasswordComponent } from '@ca/vns-ng-core';

@Injectable({
  providedIn: 'root',
})
export class CmsService {
  pageNavigationEvents$: Observable<NavigationEnd> = this.router.events.pipe(
    filter((e) => e instanceof NavigationEnd)
  ) as Observable<NavigationEnd>;
  private readonly EXCEPTIONS = [
    'login',
    'dossier',
    'nieuw-dossier',
    'wachtwoord-opnieuw-instellen',
    'wachtwoord-vergeten',
    'dossiers',
  ];

  public pageNavObserver = {
    next: async (event: NavigationEnd) => {
      const params = event.url.split('/');
      const key = params[event.url.startsWith('/') ? 1 : 0].split('#')[0];

      console.log('route event key', key);

      const metaUrl = key.length === 0 ? 'home' : key;
      // TODO: check if page is loaded before triggering event

      if (!this.EXCEPTIONS.includes(key)) {
        console.log('checking store for', metaUrl);
        const pageData = await firstValueFrom(this.store.select((s) => s.pages[metaUrl]));
        console.log('pageData', pageData);
        if (pageData)
          this.document.body.classList.value = `ca-typography ${PageID[
            pageData.id
          ].toLocaleLowerCase()}`;
        else this.document.body.classList.value = 'ca-typography';

        if (!pageData)
          this.store.dispatch(
            LoadPage({
              metaUrl,
            })
          );
        else console.log('page already loaded');
      } else {
        this.document.body.classList.value = `ca-typography login ${metaUrl}`;
      }
    },
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store<{ pages: PagesState }>,
    private auth: AuthService,
    @Inject(DOCUMENT) private document: Document
  ) {
    console.log('api base url', environment.apiBaseUrl);
    console.log('resource base url', environment.baseUrl);
    console.log('v-ns api base url', environment.backendUrl);
  }

  init() {
    return this.fetchMenu().then(async (res) => {
      // TODO: check auth
      if (res.success) {
        this.store.dispatch(
          LoadPagesInfoSuccess({
            pages: res.pages,
          })
        );
        if (this.auth.bearerToken) {
          this.auth.validateAccessToken().then((b) => {
            this.auth.clearTokenSchedule();
            this.auth.scheduleTokenRefresh();
            console.log('bearer token valid::', b);
          });
        }
      }
    });
  }

  fetchMenu() {
    return firstValueFrom(
      this.http.get<CMSGeneral>(`${environment.apiBaseUrl}api/website/general`)
    );
  }

  getPage(metaUrl: string) {
    return firstValueFrom(
      this.http.get<Page>(`${environment.apiBaseUrl}api/website/page/${metaUrl}`)
    );
  }

  configureRoutes(publicRoutes: PageInfo[], protectedRoutes: PageInfo[]): void {
    console.log('public routes', publicRoutes);
    console.log('protected routes', protectedRoutes);
    this.router.resetConfig([
      ...ConfigureRoutes([...publicRoutes, ...protectedRoutes]),
      {
        path: 'login',
        component: LoginComponent,
        data: {
          animation: 'Login',
          breadcrumb: 'Login',
        },
      },
      {
        path: 'wachtwoord-opnieuw-instellen/:code',
        component: ResetPasswordComponent,
        data: {
          animation: 0,
          breadcrumb: 'Wachtwoord instellen',
        },
      },
      {
        path: 'wachtwoord-vergeten',
        component: ForgotPasswordComponent,
        data: {
          animation: 0,
          breadcrumb: 'Wachtwoord vergeten',
        },
      },
      {
        component: NewTicketFormComponent,
        path: 'nieuw-dossier',
        canActivate: [AuthGuardService],
        data: {
          animation: 1001,
          breadcrumb: 'Nieuw dossier',
        },
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
        data: {
          animation: PageID.Home,
        },
      },
      {
        path: '**',
        component: PageNotFoundComponent,
      },
    ]);
  }
}
