import { Component, Input } from '@angular/core';
import { TicketStatus } from '@ca/vns-models';
import { Store } from '@ngrx/store';
import { openSetTicketStatusDialog } from '../../store/actions/tickets.actions';

@Component({
  selector: 'ca-ticket-status-chip',
  template: `<mat-chip [class]="status" (click)="openSetStatus()">{{ status }}</mat-chip>`,
  styles: [
    `
      mat-chip {
        span {
          color: white !important;
        }
      }
      mat-chip.Open {
        background-color: blue !important;
      }
      mat-chip.WaitingForVNS {
        background-color: orange !important;
      }
      mat-chip.WaitingForThirdParty {
        background-color: lightgrey !important;
      }
      mat-chip.WaitingForClient {
        background-color: green !important;
      }
      mat-chip.Closed {
        background-color: grey !important;
      }
    `,
  ],
  standalone: false,
})
export class TicketStatusChipComponent {
  @Input() status: TicketStatus = 'Closed';
  @Input() ticket_id!: string;
  constructor(private store: Store) {}
  openSetStatus() {
    this.store.dispatch(
      openSetTicketStatusDialog({
        currentValue: this.status,
        ticket_id: this.ticket_id,
      })
    );
  }
}
// 'Open'
//   | 'WaitingForVNS'
//   | 'WaitingForThirdParty'
//   | 'WaitingForClient'
//   | 'Closed'
