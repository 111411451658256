import {
  CreateResultDTO,
  Message,
  SendMessageWsEvent,
  TicketStatusChangedSocketEventPayload,
} from '@ca/vns-models';
import { createAction, props } from '@ngrx/store';
import { VNSChatContext } from '../../components/vns-chat/vns-chat.component';

export const SendMessage = createAction('[Message] send', props<{ message: SendMessageWsEvent }>());

export const NewMessageReceived = createAction('[Message] received', props<{ message: Message }>());

export const TicketCreatedEvent = createAction(
  '[Socket Event] Ticket created',
  props<{ payload: CreateResultDTO }>()
);
export const TicketStatusChanged = createAction(
  '[Socket Event] Ticket status changed',
  props<{
    payload: TicketStatusChangedSocketEventPayload;
  }>()
);

export const SendAttachment = createAction(
  '[Attachment] send',
  props<{ formData: FormData; context: VNSChatContext }>()
);
export const SendAttachmentSuccess = createAction(
  '[Attachment] sent',
  props<{ context: VNSChatContext; res: any }>()
);
export const SendAttachmentFailure = createAction(
  '[Attachment] failed to send',
  props<{ formData: FormData; context: VNSChatContext; error: any }>()
);
// export const SocketEvent = createAction('[Socket] event', props<{ event: SocketEventType }>());

export const LoadMessagesForContext = createAction(
  '[Messages] load for context',
  props<{ ticket_id: string }>()
);

export const LoadMessagesForContextSuccess = createAction(
  '[Messages] loaded for context',
  props<{ ticket_id: string; messages: Message[] }>()
);

export const LoadMessagesForContextFailure = createAction(
  '[Messages] failed to load for context',
  props<{ ticket_id: string; error: any }>()
);
