import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
// import { Login, LoginFailure } from '../../store/reducers/user.reducer';
import { FormBuilder, Validators } from '@angular/forms';
import { LoginRequestDTO } from '@ca/vns-models';
import { Actions, ofType } from '@ngrx/effects';
import { Subscription, map } from 'rxjs';
import { SnackbarService } from '@ca/ca-snackbar';
import { Login, LoginFailure } from '../../store/reducers/user.reducer';
// import { AppState } from '../../store/reducers/app.reducer';

@Component({
  selector: 'ca-login',
  template: `
    <div class="login-form" [formGroup]="loginForm">
      <p>
        <mat-form-field>
          <mat-label>E-Mail</mat-label>
          <input type="text" matInput formControlName="email" required />
          <mat-error>Dit is geen geldig mailadres</mat-error>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field>
          <mat-label>Wachtwoord</mat-label>
          <input type="password" (keyup.enter)="login()" matInput formControlName="password" />
          <mat-error>Dit is geen geldig wachtwoord</mat-error>
        </mat-form-field>
      </p>

      <a [routerLink]="['/wachtwoord-vergeten']">Wachtwoord vergeten?</a>

      <div class="actions">
        <button
          mat-raised-button
          color="primary"
          [disabled]="!this.loginForm.valid"
          (click)="login()"
          class="login-btn">
          Login
        </button>
      </div>
    </div>
  `,
  standalone: false,
})
export class LoginComponent implements OnInit, OnDestroy {
  private sub?: Subscription;
  constructor(
    private actions$: Actions,
    private fb: FormBuilder,
    private snackbar: SnackbarService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.sub = this.actions$
      .pipe(
        ofType(LoginFailure),
        map((a) => a)
      )
      .subscribe((e) => {
        console.error(e);
        this.snackbar.errorSnackbar('Ongeldige login gegevens.');
        this.loginForm.reset();
      });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  loginForm = this.fb.group({
    email: this.fb.control<string>('', [Validators.required, Validators.email]),
    password: this.fb.control<string>('', Validators.required),
  });

  login() {
    // TODO: make sure to do this only once!
    if (this.loginForm.valid) this.store.dispatch(Login(this.loginForm.value as LoginRequestDTO));
  }
}
