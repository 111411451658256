import {
  TML_ListResponseData,
  TML_ListDepartmentDTO,
  TML_ListTaxRateDTO,
  TML_ListMailTemplateDTO,
  TML_UserListDTO,
} from '@ca/tml-focus-core';
import { UserRole } from './auth.models';

//#region Subscription Setttings
/**
 * Subscription Settings
 */
export interface SubscriptionSettings {
  tml_billing_days_in_advance: 0 | 7 | 14 | 21 | 28;
  tml_department_id: string;
  tml_payment_days_after_invoice_date: number;
  tml_default_responsible_user_id: string;
  tml_subscription_document_template_id: string;
  tml_subscription_tax_rate_id: string;
  tml_subscription_yearly_fee: number;
}
export type UpdateSubscriptionSettings = Partial<SubscriptionSettings>;
export type SubscriptionSettingsData = SubscriptionSettings & { id: string };

export interface VNSSubscriptionSettingsTMLResourcesForSelect {
  tmlDepartments: TML_ListResponseData<TML_ListDepartmentDTO>;
  tmlTaxRates: TML_ListResponseData<TML_ListTaxRateDTO>;
  tmlMailTemplates: TML_ListResponseData<TML_ListMailTemplateDTO>;
  tmlUsers: TML_ListResponseData<TML_UserListDTO>;
}
//#endregion

//#region Tickets

/**
 * Tickets
 */

//#endregion

//#region Messaging
export interface Message {
  id: string;
  message: string;
  sent_at: Date;
  sent_by_name: string;
  sent_by_id: string;
  sent_by_type: UserRole;
  ticket_id: string;
  third_party: boolean;
}
export type SendMessageWsEvent = Omit<Message, 'id'>;
//#endregion

//#region Assignment of Jurist on ticket
export interface AssignJuristWsEvent {
  ticket_id: string;
  jurist_id: string;
}
export interface AssignJuristResult {
  message: 'Jurist assigned';
}
//#endregion

//#region Jurist Registration
export interface RegisterJuristDto {
  oneTimePassword: string;
  email: string;
  first_name: string;
  last_name: string;
  password: string;
}
//#endregion
