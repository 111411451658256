import { Component } from '@angular/core';
import { FEATURE_KEY } from '@ca/vns-ng-core';
import { MemberDTO } from '@ca/vns-models';
import { selectUserObject, VNSCoreState } from '@ca/vns-ng-core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ca-mijn-vns',
  templateUrl: './mijn-vns.component.html',
  styleUrls: ['./mijn-vns.component.scss'],
})
export class MijnVnsComponent {
  user$ = this.store.select((s) => selectUserObject<MemberDTO>(s));
  constructor(private readonly store: Store<{ [FEATURE_KEY]: VNSCoreState }>) {}
}
