import { Component, Input } from '@angular/core';
import { MediaItem } from '../models';

@Component({
  selector: 'ca-media-list',
  template: `<mat-list *ngIf="media && media.length > 0">
    <div mat-subheader i18n="@@upload-files">Uploaded Files</div>
    <mat-list-item *ngFor="let file of media">
      <mat-icon matListItemIcon>folder</mat-icon>
      <div matListItemTitle>{{ file.name }}</div>
      <a *ngIf="hasDownloadLink" matListItemLine [href]="file.url" class="download-link"
        >download</a
      >
    </mat-list-item>
  </mat-list>`,
  standalone: false,
  styleUrls: ['./media-list.component.scss'],
})
export class MediaListComponent {
  @Input() media: MediaItem[] = [];
  @Input() hasDownloadLink = true;
}
