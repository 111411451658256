import { createAction, props } from '@ngrx/store';

export enum AdminActions {
  assignJurist = '[Assign] Jurist to ticket',
  assignJuristSuccess = '[Assign] Assigned jurist to ticket',
  assignJuristFailure = '[Assign] Failed to assign jurist to ticket',
}

export const AssignJurist = createAction(
  AdminActions.assignJurist,
  props<{ ticket_id: string; assignee_id: string }>()
);
export const AssignJuristSuccess = createAction(
  AdminActions.assignJuristSuccess,
  props<{ ticket_id: string; assignee_id: string }>()
);
export const AssignJuristFailure = createAction(
  AdminActions.assignJuristFailure,
  props<{ err: any }>()
);
