import { Injectable, OnDestroy } from '@angular/core';
import { CaSubscriber } from '@ca/ca-utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SendMessage } from '../actions/message.actions';
import { RouterReducerState } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { SocketsService } from '../../services/sockets.service';
import { map } from 'rxjs';

@Injectable()
export class SharedEffects implements OnDestroy {
  private sub = new CaSubscriber();

  constructor(
    private readonly store: Store<{ router: RouterReducerState }>,
    private readonly actions$: Actions,
    private readonly socket: SocketsService
  ) {}

  onSendMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SendMessage),
        map(({ message }) => {
          this.socket.sendMessage(message);
        })
      ),
    { dispatch: false }
  );

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }
}
