export async function copyToClipboard(value: any) {
  try {
    if (!navigator.clipboard) {
      throw new Error("Browser don't have support for native clipboard.");
    }
    await navigator.clipboard.writeText(value);
  } catch (e) {
    console.log(e);
  }
}

export function getPath() {
  const path = (
    window.location.pathname.startsWith('/')
      ? window.location.pathname.slice(1)
      : window.location.pathname
  ).split('/');
  return path;
}
