import { Component, OnDestroy } from '@angular/core';
import { CaSubscriber } from '@ca/ca-utils';
import { SendTicketDTO } from '@ca/vns-models';
import { Store } from '@ngrx/store';
import { SendTicket } from '../../store/reducers/member.actions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FEATURE_KEY, VNSCoreState } from '@ca/vns-ng-core';

@Component({
  selector: 'ca-new-ticket-form',
  template: `<form [formGroup]="fg">
    <mat-form-field>
      <mat-label>Onderwerp</mat-label>
      <input matInput formControlName="title" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Bericht</mat-label>
      <textarea matInput formControlName="message" [rows]="10"></textarea>
    </mat-form-field>

    <button [disabled]="!fg.valid" (click)="sendTicket()" class="cta-btn">
      Nieuw dossier openen
    </button>
  </form> `,
  styles: ['form {display: flex;flex-direction: column;margin: 1.33rem;}'],
})
export class NewTicketFormComponent implements OnDestroy {
  private sub = new CaSubscriber();
  protected fg: FormGroup = this.fb.group({
    title: this.fb.control(null, Validators.required),
    message: this.fb.control(null, Validators.required),
  });

  constructor(private store: Store<{ [FEATURE_KEY]: VNSCoreState }>, private fb: FormBuilder) {}

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  sendTicket() {
    const sendTicketDTO: SendTicketDTO = this.fg.value;
    console.log(sendTicketDTO);
    this.store.dispatch(SendTicket(sendTicketDTO));
  }
}
