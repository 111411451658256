import { TicketListItemDTO, Ticket } from '@ca/vns-models';
import { createReducer, on } from '@ngrx/store';
import { LogoutSuccess } from './user.reducer';
import { LoadTicketsSuccess, LoadTicketDetailSuccess } from '../actions/tickets.actions';

export interface TicketsState {
  tickets: TicketListItemDTO[];
  detail?: Ticket;
}

const initialState: TicketsState = {
  tickets: [],
};

export const reducer = createReducer(
  initialState,
  on(LoadTicketsSuccess, (_, { tickets }) => ({
    ..._,
    tickets,
  })),
  on(LoadTicketDetailSuccess, (_, { ticket }) => ({
    ..._,
    detail: ticket,
  })),
  on(LogoutSuccess, (_) => initialState)
);
