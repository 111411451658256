import { CreateResultDTO, SendTicketDTO } from '@ca/vns-models';
import { createAction, props } from '@ngrx/store';

export enum MemberActions {
  sendTicket = '[Ticket] Send',
  sendTicketSuccess = '[Ticket] Sent successfully',
  sendTicketFailure = '[Ticket] Failed to send',
}

export const SendTicket = createAction(MemberActions.sendTicket, props<SendTicketDTO>());
export const SendTicketSuccess = createAction(
  MemberActions.sendTicketSuccess,
  props<CreateResultDTO>()
);
export const SendTicketFailure = createAction(
  MemberActions.sendTicketFailure,
  props<{ error: any }>()
);
