import { createReducer, on } from '@ngrx/store';
import { OnboardingState, UserState } from '../../types/interfaces';
import {
  UnauthorizedHttpRequest,
  UnauthorizedPageLoad,
  loadDivisionsSuccess,
  loadProfileSuccess,
  loadRolesSuccess,
  loadUserSuccess,
  loadUsersSuccess,
  loginSuccess,
  logout,
  twoFaSuccess,
  updateProfileSuccess,
} from '../actions';
import { setActiveDivision } from '../actions/division.actions';

const userString = () => sessionStorage.getItem('ca-user');

const user: () => UserState = () => (userString() ? JSON.parse(userString() as string) : null);

export const selectUser = (state: { onboarding: OnboardingState }) => state.onboarding.user;
export const emptyState: UserState = {
  hasMultipleDivisions: false,
};
export const initialState: UserState = user() ?? emptyState;

export const reducer = createReducer(
  initialState,
  on(loginSuccess, (_, result) => ({
    ..._,
    profile: result.profile,
    avatarUrl: result.avatar,
    token: result.token,
    refreshToken: result.refreshToken,
    activeDivision: result.profile?.linkedDivisions.find((e) => e.isDefault),
    hasMultipleDivisions: result.profile !== undefined && result.profile.linkedDivisions.length > 0,
    user: result.user,
  })),
  on(twoFaSuccess, (_, result) => ({
    ..._,
    avatarUrl: result.avatar,
    profile: result.profile,
    token: result.token,
  })),
  on(logout, () => emptyState),
  on(loadProfileSuccess, (_, { profile }) => ({
    ..._,
    profile: profile,
  })),
  on(updateProfileSuccess, (_, { current, request }) => ({
    ..._,
    profile: { ...current, ...request },
  })),
  on(loadUsersSuccess, (_, { users }) => ({
    ..._,
    users,
  })),
  on(loadDivisionsSuccess, (_, { divisions }) => ({
    ..._,
    divisions,
  })),
  on(loadRolesSuccess, (_, { roles }) => ({
    ..._,
    roles,
  })),
  on(setActiveDivision, (_, { division }) => ({
    ..._,
    activeDivision: division,
  })),
  on(loadUserSuccess, (_, { user }) => ({ ..._, user })),
  on(UnauthorizedPageLoad, (_) => emptyState),
  on(UnauthorizedHttpRequest, (_) => emptyState)
);
