import { VNSEnvironment } from '@ca/vns-models';

export const environment: VNSEnvironment = {
  production: true,
  apiBaseUrl: 'https://backend.vns.dev.cigar.agency/',
  baseUrl: 'https://backend.vns.dev.cigar.agency/',
  appUrl: 'http://localhost:4200',
  backendUrl: 'http://localhost:3000',
  sessionStorageBearerKey: 'ca-token',
  recaptchaSiteKey: '6LeuRAcqAAAAAJyLKIhuqnZxyHiXd_WritDsTd5g',
  stripePublicKey:
    'pk_test_51PdXNJRvbFGrm0OiYYXZaAGT7tdtqLXZLAz3D4FTTiK3GCD5YccI74gPdvKDSRuwPBKUPpjZmIeUo1koRzJ7s2yb00TtHbigfw',
};
