import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';

import { ActivatedRoute } from '@angular/router';
import { CaSubscriber } from '@ca/ca-utils';
import { BreadcrumbService } from '../../services/breadcrumb.service';

import { MemberDTO, UserRole } from '@ca/vns-models';
import { ConversationComponent } from '@ca/chat-ui';
import {
  FEATURE_KEY,
  LoadTicketDetail,
  selectTicketDetail,
  SocketsService,
  VNSCoreState,
} from '@ca/vns-ng-core';
import { Observable } from 'rxjs';
import { DateTime } from 'luxon';

@Component({
  selector: 'ca-ticket-detail',
  templateUrl: './ticket-detail.component.html',
  styleUrls: ['./ticket-detail.component.scss'],
})
export class TicketDetailComponent implements OnInit, OnDestroy {
  @ViewChild(ConversationComponent) chat!: ConversationComponent;
  sub = new CaSubscriber();
  detail$ = this.store.select(selectTicketDetail);
  ticketId?: string;
  user: MemberDTO | undefined;
  user$ = this.store.select((s) => s[FEATURE_KEY].user.user) as Observable<MemberDTO | undefined>;

  constructor(
    private store: Store<{ [FEATURE_KEY]: VNSCoreState }>,
    private route: ActivatedRoute,
    private breadcrumbs: BreadcrumbService,
    private ws: SocketsService
  ) {}

  ngOnDestroy(): void {
    this.sub?.closeSubscriptions();
  }

  ngOnInit(): void {
    this.sub.subscribe(this.detail$, {
      next: (d) => {
        if (d) this.breadcrumbs.setDynamicSegmentTitle(d?.title);
        // setTimeout(() => {
        //   if (this.chat === undefined) throw new Error('Chat UI is not defined');
        //   if (this.user && this.chat) this.chat.ownerId = this.user.id;
        //   if (this.chat.messages.length === 0 && d?.messages)
        //     this.chat.messages.push(...d.messages);
        // }, 300);
      },
    });

    this.sub.subscribe(this.user$, {
      next: (user?: MemberDTO) => {
        if (user) this.user = user as MemberDTO;
        if (user && this.chat) this.chat.ownerId = user.id;
      },
    });

    this.sub.subscribe(this.route.params, {
      next: (params) => {
        console.log(params);
        const id = params['metaUrl'];
        if (!id) throw new Error('Required parameter ID was not provided.');
        this.ticketId = id;
        this.store.dispatch(
          LoadTicketDetail({
            id: id,
          })
        );
      },
    });
  }

  sendMessage(e: { message: string }) {
    if (!this.user) throw new Error('user not found');
    if (!this.ticketId) throw new Error('ticket not found');
    console.log('sending message', e.message);
    const message = {
      message: e.message,
      sent_by_id: this.user.id,
      sent_by_name: this.user.first_name,
      ticket_id: this.ticketId,
      sent_at: DateTime.now().toJSDate(),
      sent_by_type: 'member' as UserRole,
      third_party: false,
    };
    this.ws.sendMessage(message);
  }
}
