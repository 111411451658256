import { ModuleWithProviders, NgModule } from '@angular/core';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { SnackbarModule } from '@ca/ca-snackbar';
import { CaAreYouSureModule } from '@ca/ca-are-you-sure';
import { CaDataModuleConfiguration } from './CaDataModuleConfiguration';

@NgModule({ imports: [CaAreYouSureModule, SnackbarModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class CaDataModule {
  static forRoot(
    configuration: CaDataModuleConfiguration
  ): ModuleWithProviders<CaDataModule> {
    return {
      ngModule: CaDataModule,
      providers: [
        {
          provide: CaDataModuleConfiguration,
          useValue: configuration,
        },
      ],
    };
  }
}
