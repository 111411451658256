import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { map, of } from 'rxjs';

@Component({
  selector: 'ca-conversation',
  template: `
    <div id="ca-chat-conversation">
      <ng-container *ngIf="ownerId">
        <div class="messages">
          <ca-message
            *ngFor="let msg of messages$ | async"
            [direction]="msg.sent_by_id === ownerId ? 'outgoing' : 'incoming'"
            [message]="msg"></ca-message>
        </div>
        <!-- TODO: also set fileOver to false on error -->
        <div
          id="chat-ui-form"
          [class.fileOver]="fileOver"
          (dragenter)="fileOver = true"
          (dragleave)="fileOver = false">
          <input type="file" multiple hidden #fileUpload />
          <mat-form-field *ngIf="!fileOver" (dragenter)="fileOver = true" appearance="outline">
            <input
              name="message"
              (keyup.enter)="send()"
              matInput
              [(ngModel)]="message"
              type="text" />

            <button mat-icon-button matSuffix (click)="fileUpload.click()">
              <mat-icon>upload</mat-icon>
            </button>

            <button mat-icon-button matSuffix (click)="send()">
              <mat-icon>send</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </ng-container>
    </div>
  `,
  styles: [
    `
      .messages {
        min-height: 8rem;
        display: flex;
        flex-direction: column;
        padding: 0.33rem;
        ca-message {
          width: fit-content;
        }
        ca-message[ng-reflect-direction='incoming'] {
          align-self: flex-start;
        }
        ca-message[ng-reflect-direction='outgoing'] {
          align-self: flex-end;
        }
      }
      #ca-chat-conversation {
        height: fit-content;
        margin: 0.66rem;
        padding: 0 0.66rem;
        border-radius: 0.66rem;
        border: 1px solid black;
      }
      #chat-ui-form {
        padding: 0 1.33rem;
        width: 100%;
        min-height: 5rem;
      }
      #chat-ui-form.fileOver {
        background-color: red;
      }
      mat-form-field {
        width: calc(100% - 2.66rem);
      }
    `,
  ],
})
export class ConversationComponent implements OnInit {
  @Output() newMessage: EventEmitter<{ message: string }> = new EventEmitter<{ message: string }>();
  @Output() newFiles: EventEmitter<{ files: FileList }> = new EventEmitter<{ files: FileList }>();

  @Input() messages: ChatMessage[] = [];
  @Input() ownerId?: string;

  addMessage(message: ChatMessage) {
    this.messages.push(message);
  }

  message: string | null = null;
  fileOver = false;

  messages$ = of(this.messages).pipe(map(mapMessages));

  ngOnInit(): void {
    if (this.messages.length > 0) this.messages$ = of(this.messages).pipe(map(mapMessages));
  }

  uploadFile() {
    console.log('opening file upload');
  }

  send() {
    const message = this.message;
    if (message && message.length > 0) {
      console.log(`sending message: ${this.message}`);
      this.newMessage.emit({ message });
      this.message = null;
    }
  }
}

const mapMessages = (m: ChatMessage[]) =>
  [...m].sort((a: ChatMessage, b: ChatMessage) =>
    moment(a.sent_at).isBefore(moment(b.sent_at))
      ? -1
      : moment(b.sent_at).isBefore(moment(a.sent_at))
      ? 1
      : 0
  );

export interface ChatMessage {
  id: string;
  message: string;

  sent_at: Date;
  sent_by_id: string;
  sent_by_name: string;
  sent_by_type: string;
}
