import { ILinkedDivision, IUserDivision, IUserProfile, IUserRole } from '../V2';

export interface UserState<T = any> {
  profile?: IUserProfile;
  token?: string;
  refreshToken?: string;
  avatarUrl?: string;
  users?: IUserProfile[];
  divisions?: IUserDivision[];
  roles?: IUserRole[];
  activeDivision?: ILinkedDivision;
  hasMultipleDivisions: boolean;
  user?: T;
}
