import { Component, Input, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AccordeonPlugin } from '@ca/vns-models';
import { MatAccordion } from '@angular/material/expansion';
import { Observable } from 'rxjs';

@Component({
  selector: 'ca-accordeon-plugin',
  template: `<mat-accordion>
    <mat-expansion-panel
      *ngFor="let panel of plugin.accordionItems; let i = index"
      [id]="plugin.pluginId + panel.id"
      [expanded]="(activeFragment$ | async) === plugin.pluginId + panel.id"
      hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ panel.title }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <img *ngIf="panel.img" [src]="resourceUrlBase + panel.img.src" [alt]="panel.img.alt" />
      <div *ngIf="panel.text && panel.text.length > 0" [innerHTML]="panel.text"></div>

      <div *ngIf="panel.mediaFiles">
        <mat-nav-list>
          <a
            *ngFor="let f of panel.mediaFiles"
            [href]="resourceUrlBase + f.downloadUrl"
            target="_blank">
            {{ f.title }}</a
          >
        </mat-nav-list>
      </div>
      <ca-cta [plugin]="panel"></ca-cta>
    </mat-expansion-panel>
  </mat-accordion> `,
})
export class AccordeonPluginComponent {
  @ViewChild(MatAccordion) accordeon!: MatAccordion;

  @Input() plugin!: AccordeonPlugin;
  @Input() activeFragment$!: Observable<string | null>;

  resourceUrlBase = environment.baseUrl;
}
