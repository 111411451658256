import { Injectable } from '@angular/core';
import { TicketsService } from '../../services/tickets.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, firstValueFrom, map } from 'rxjs';
import {
  LoadTickets,
  LoadTicketsSuccess,
  LoadTicketsFailure,
  LoadTicketDetail,
  LoadTicketDetailSuccess,
  LoadTicketDetailFailure,
  openSetTicketStatusDialog,
} from '../actions/tickets.actions';
import {
  LoadMessagesForContext,
  LoadMessagesForContextFailure,
  LoadMessagesForContextSuccess,
} from '../actions/message.actions';
import { MatDialog } from '@angular/material/dialog';
import { SetStatusDialogComponent } from '../../components/set-status-dialog/set-status-dialog.component';

@Injectable()
export class TicketsEffects {
  constructor(
    private readonly actions$: Actions,
    private tickets: TicketsService,
    private dialog: MatDialog
  ) {}

  onOpenSetTicketStatusDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openSetTicketStatusDialog),
        map(({ currentValue, ticket_id }) =>
          this.dialog.open(SetStatusDialogComponent, { data: { currentValue, ticket_id } })
        )
      ),
    { dispatch: false }
  );

  onLoadTickets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadTickets),
      switchMap(() =>
        firstValueFrom(this.tickets.getTickets())
          .then((res) => LoadTicketsSuccess({ tickets: res }))
          .catch((err) => LoadTicketsFailure({ error: err }))
      )
    )
  );

  onLoadTicketDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadTicketDetail),
      switchMap(({ id: metaUrl }) =>
        firstValueFrom(this.tickets.getTicketDetail(metaUrl))
          .then((res) => LoadTicketDetailSuccess({ ticket: res }))
          .catch((err) => LoadTicketDetailFailure({ error: err }))
      )
    )
  );

  onLoadMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadMessagesForContext),
      switchMap(({ ticket_id }) =>
        firstValueFrom(this.tickets.getMessagesForTicket(ticket_id))
          .then((messages) => LoadMessagesForContextSuccess({ ticket_id, messages }))
          .catch((error) => LoadMessagesForContextFailure({ ticket_id, error }))
      )
    )
  );
}
