import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AuthService } from './services/auth.service';
import { AuthGuardService } from '@ca/onboarding';
import { LoginComponent } from './components/login/login.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UnauthorizedInterceptor } from './interceptors/unauthorized.interceptor';
import { CaNgCoreModule } from '@ca/ca-ng-core';
import { StoreModule } from '@ngrx/store';
import { FEATURE_KEY } from './config';
import { metaReducers, ROOT_REDUCER } from './store';
import { LoginEffects } from './store/effects/login.effects';
import { EffectsModule } from '@ngrx/effects';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { VNSCoreConfig } from './models/config.models';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { TicketStatusChipComponent } from './components/ticket-status-chip/ticket-status-chip.component';
import { MatChipsModule } from '@angular/material/chips';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { RouterModule } from '@angular/router';
import { IntersectionDirective } from './directives/intersection.directive';
import { SnackbarModule } from '@ca/ca-snackbar';
import { SharedEffects } from './store/effects/shared.effects';
import { SocketsService } from './services/sockets.service';
import { VnsChatComponent } from './components/vns-chat/vns-chat.component';
import { MatIconModule } from '@angular/material/icon';
import { MessageComponent } from './components/vns-chat/message.component';
import { TicketsService } from './services/tickets.service';
import { TicketsEffects } from './store/effects/tickets.effects';
import { FilesEffects } from './store/effects/files.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_KEY, ROOT_REDUCER, {
      metaReducers,
    }),
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    SnackbarModule,
    CaNgCoreModule,

    EffectsModule.forFeature([LoginEffects, FilesEffects, SharedEffects, TicketsEffects]),
    RouterModule.forChild([]), // Adding routes here doesn't seem to work.. Because of the dynamic reinstanstiation of routes in cms service?
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
    AuthService,
    AuthGuardService,
    SocketsService,
    TicketsService,
  ],
  declarations: [
    LoginComponent,
    ForbiddenComponent,
    TicketStatusChipComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    IntersectionDirective,
    VnsChatComponent,
    MessageComponent,
  ],
  exports: [ForbiddenComponent, TicketStatusChipComponent, IntersectionDirective, VnsChatComponent],
})
export class VnsNgCoreModule {
  static forRoot(configuration: VNSCoreConfig) {
    return {
      ngModule: VnsNgCoreModule,
      providers: [
        {
          provide: VNSCoreConfig,
          useValue: configuration,
        },
      ],
    };
  }
}
