import { CaEnvironment } from '@ca/ca-utils';

export type VNSEnvironment = CaEnvironment & {
  appUrl: string;
  /**
   * v-ns API url
   */
  backendUrl: string;
  recaptchaSiteKey: string;
  stripePublicKey: string;
};
