import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SendTicket, SendTicketFailure, SendTicketSuccess } from '../reducers/member.actions';

import { firstValueFrom, switchMap } from 'rxjs';
import { TicketsService } from '@ca/vns-ng-core';

@Injectable()
export class MyFileEffects {
  constructor(private readonly actions$: Actions, private tickets: TicketsService) {}

  onNewTicket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SendTicket),
      switchMap(({ title, message }) =>
        firstValueFrom(this.tickets.createTicket(title, message))
          .then((res) => SendTicketSuccess(res))
          .catch((error) => SendTicketFailure({ error }))
      )
    )
  );
}
