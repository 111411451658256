import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RegisterDTO, RegisterResultDTO } from '@ca/vns-models';
import { environment } from '../../environments/environment';

@Injectable()
export class RegistrationsService {
  constructor(private http: HttpClient) {}

  post(dto: RegisterDTO) {
    return this.http.post<RegisterResultDTO>(
      `${environment.backendUrl}/api/registrations/add`,
      dto
    );
  }
}
