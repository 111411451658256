<h1 mat-dialog-title>Wijzig dossier status</h1>

<div mat-dialog-content>
  <mat-form-field>
    <mat-label></mat-label>
    <mat-select [(value)]="status">
      <mat-option value="Open">Open</mat-option>
      <mat-option value="WaitingForVNS">Wachten op V-NS</mat-option>
      <mat-option value="WaitingForThirdParty">Wachten op derde partij </mat-option>
      <mat-option value="WaitingForClient">Wachten op lid</mat-option>
      <mat-option value="Closed">Gesloten</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div mat-dialog-actions align="end">
  <a class="cancel-link cancel" style="margin-right: 0.66em" mat-dialog-close>Annuleer</a>
  <button
    mat-raised-button
    color="primary"
    type="button"
    (click)="updateStatus()"
    [disabled]="data.currentValue === status">
    Bewaar
  </button>
</div>
