export * from './lib/vns-ng-core.module';
export * from './lib/components';

export { AuthService } from './lib/services/auth.service';
export { AuthGuardService } from './lib/services/auth-guard.service';
export { SocketsService } from './lib/services/sockets.service';
export { TicketsService } from './lib/services/tickets.service';

export { VNS_ENVIRONMENT, StrongPasswordRegx } from './lib/config';
export { UnauthorizedInterceptor } from './lib/interceptors/unauthorized.interceptor';
// STORE

export * from './lib/store/actions/admin.actions';
export * from './lib/store/reducers/admin.reducer';
export * from './lib/store/reducers/user.reducer';
export * from './lib/store/actions/tickets.actions';
export * from './lib/store/actions/message.actions';
export * from './lib/store/effects/login.effects';
export * from './lib/store/selectors';
export * from './lib/store/index';

export { VNSCoreConfig } from './lib/models/config.models';
export * from './lib/components';
export { IntersectionDirective } from './lib/directives/intersection.directive';
// TODO: read this to also export base scss and images
// https://coco-boudard.medium.com/adding-scss-assets-to-angular-library-33a9ed0654fa
