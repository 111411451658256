<section *ngIf="user$ | async as user">
  <div>
    <h1>Persoonsgegevens</h1>
    <span class="prop">
      <span class="prop-label">voornaam</span>
      <span class="prop-value">{{ user.last_name }}</span>
    </span>
    <span class="prop">
      <span class="prop-label">naam</span>
      <span class="prop-value">{{ user.first_name }}</span>
    </span>
    <span class="prop">
      <span class="prop-label">e-mail</span>
      <span class="prop-value">{{ user.email }}</span>
    </span>
  </div>

  <div>
    <h1>Lidmaatschap</h1>
    <span class="prop"
      ><span class="prop-label">bedrijfsnaam</span>
      <span class="prop-value">{{ user.member_data.company_name }}</span></span
    >
    <span class="prop"
      ><span class="prop-label">lid sinds</span>
      <span class="prop-value">{{ user.member_data.started_date | date : 'd/M/yy' }}</span></span
    >
    <span class="prop"
      ><span class="prop-label">volgende verlenging</span>
      <span class="prop-value">{{
        user.member_data.next_renewal_date | date : 'd/M/yy'
      }}</span></span
    >
  </div>
</section>
