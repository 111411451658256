import { Component, OnDestroy } from '@angular/core';
import { MemberDTO, PageInfo, PageLinkInfo } from '@ca/vns-models';
import { ChildrenOutletContexts, Router } from '@angular/router';
import { AppState } from './store/reducers/app.reducer';
import { Store } from '@ngrx/store';
import { CaSubscriber } from '@ca/ca-utils';
import { PagesState } from './store/reducers/pages.reducer';
// import { slideInAnimation } from './animations';
import { CmsService } from './services/cms.service';

import { FEATURE_KEY, Logout, UserState } from '@ca/vns-ng-core';
import { getPath } from '@ca/ca-ng-core';

@Component({
  selector: 'ca-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // animations: [slideInAnimation],
})
export class AppComponent implements OnDestroy {
  protected nav: PageNav = {
    protectedRoutes: [],
    publicRoutes: [],
    footerMenuLinks: [],
    primaryMenuLinks: [],
    secondaryMenuLinks: [],
    dropdownLinks: [],
  };
  protected isLoggedIn = false;
  protected user?: MemberDTO;
  private sub = new CaSubscriber();

  constructor(
    private contexts: ChildrenOutletContexts,
    private router: Router,
    private cms: CmsService,
    private store: Store<{ app: AppState; pages: PagesState; [FEATURE_KEY]: { user: UserState } }>
  ) {
    const path = getPath();

    this.sub.subscribe(
      this.store.select((s) => s[FEATURE_KEY].user.isLoggedIn),
      { next: (isLoggedIn) => (this.isLoggedIn = isLoggedIn) }
    );

    this.sub.subscribe(
      this.store.select((s) => s.app),
      {
        next: ({
          protectedRoutes,
          publicRoutes,
          footerMenuLinks,
          primaryMenuLinks,
          secondaryMenuLinks,
          dropdownLinks,
        }) => {
          this.nav = {
            protectedRoutes,
            publicRoutes,
            footerMenuLinks,
            primaryMenuLinks,
            secondaryMenuLinks,
            dropdownLinks,
          };
          // TODO: canActivate!!!
          this.cms.configureRoutes(publicRoutes, protectedRoutes);
          this.router.navigate(path);
        },
      }
    );
    this.sub.subscribe(this.cms.pageNavigationEvents$, this.cms.pageNavObserver);
    this.sub.subscribe(
      this.store.select((s) => s[FEATURE_KEY].user),
      {
        next: (s: UserState) => {
          this.user = s.user as MemberDTO | undefined;
          this.isLoggedIn = s.isLoggedIn;
        },
      }
    );
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }

  logOut() {
    this.store.dispatch(Logout());
  }
}

export interface PageNav {
  protectedRoutes: PageInfo[];
  publicRoutes: PageInfo[];
  footerMenuLinks: PageLinkInfo[];
  primaryMenuLinks: PageLinkInfo[];
  secondaryMenuLinks: PageLinkInfo[];
  dropdownLinks: PageLinkInfo[];
}
