import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map } from 'rxjs/operators';
import { OnboardingService } from '../../services';
import {
  ILoginSuccessApiResponse,
  OnboardingModuleConfiguration,
  OnboardingState,
  TwoFaRequestActionData,
} from '../../types';
import { twoFaSuccess } from '../actions';
import { OnboardingAction } from '../config';
import { OnboardingEffects } from './onboarding.effects';
import { Router } from '@angular/router';
import { SnackbarService } from '@ca/ca-snackbar';
import { LoggingService } from '@ca/ca-ng-core';
import { Store } from '@ngrx/store';

@Injectable()
export class TwoFactorEffects extends OnboardingEffects {
  constructor(
    protected override config: OnboardingModuleConfiguration,
    protected override svc: OnboardingService,
    protected override actions$: Actions,
    protected override router: Router,
    protected override snackbar: SnackbarService,
    protected override logger: LoggingService,
    protected override store: Store<{ onboarding: OnboardingState }>
  ) {
    super(config, svc, actions$, router, snackbar, logger, store);
  }

  onSubmit2FactorCode$ = this.config.disableEffects
    ? null
    : createEffect(() =>
        this.actions$.pipe(
          ofType(OnboardingAction.TWO_FA_REQUEST),
          switchMap((value: TwoFaRequestActionData) =>
            this.svc.validate2FA(value.data).pipe(
              map((res: ILoginSuccessApiResponse & { valid: boolean }) => {
                if (res.success && res.valid)
                  return twoFaSuccess({
                    avatar: res.avatar,
                    profile: res.profile,
                    token: res.bearerToken,
                  });
                else if (res.success) return this.QueueError('Invalid code.');
                else return this.QueueError('2-Step Verification failed.');
              })
            )
          )
        )
      );
}
