import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { CaSubscriber } from '@ca/ca-utils';
import { NewsArticle, VNSEnvironment } from '@ca/vns-models';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/reducers/app.reducer';
import { PagesState } from '../../store/reducers/pages.reducer';
import { Router } from '@angular/router';
import { AnalyticsService } from '../../services/analytics.service';
import { VNS_ENVIRONMENT } from '@ca/vns-ng-core';

@Component({
  selector: 'ca-news-article-summary',
  template: `
    <div class="news-article-summary" (click)="readMore()">
      <img
        *ngIf="article.img"
        class="mat-card-image"
        [src]="baseUrl + article.img.src"
        [alt]="article.img.alt" />
      <div class="gradient-box"></div>
      <div class="content-box">
        <div class="flex-content">
          <mat-chip-listbox>
            <ng-container *ngFor="let tg of article.tag_groups">
              <mat-chip *ngFor="let t of tg.tags">{{ t.name }}</mat-chip>
            </ng-container>
          </mat-chip-listbox>

          <h3>{{ article.title }}</h3>

          <div [innerHTML]="article.short_text"></div>
          <div class="card-actions">
            <button (click)="readMore()">Lees meer</button>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class NewsArticleSummaryComponent implements OnInit, OnDestroy {
  @Input() article!: NewsArticle;
  private sub = new CaSubscriber();
  baseUrl = this.env.apiBaseUrl;

  constructor(
    @Inject(VNS_ENVIRONMENT) private env: VNSEnvironment,
    private readonly store: Store<{ app: AppState; pages: PagesState }>,
    private router: Router,
    private readonly analytics: AnalyticsService
  ) {}

  ngOnInit(): void {
    if (!this.article) throw new Error('Article is not defined');
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  readMore() {
    // if (!this.newsRouteMetaUrl) throw new Error('Route info for news unknown');
    // TODO: get metaUrl for news articles
    this.analytics.trackEvent('clicked read more', this.article.title, 'content opened');
    // TODO: navigate to route
    this.router.navigate([this.article.parentMetaUrl, this.article.metaUrl]);
  }
}
