import { UserDTO } from '@ca/vns-models';
import { VNSCoreState } from '.';
import { FEATURE_KEY } from '../config';

// USER
export const selectIsLoggedIn = (s: { [FEATURE_KEY]: VNSCoreState }) =>
  s[FEATURE_KEY].user.isLoggedIn;
export const selectUserObject = <T extends UserDTO>(s: { [FEATURE_KEY]: VNSCoreState }) =>
  s[FEATURE_KEY].user.user as T;

// TICKETS
export const selectTicketDetail = (s: { [FEATURE_KEY]: VNSCoreState }) =>
  s[FEATURE_KEY].tickets.detail;

// MESSAGES
export const selectMessagesForContext =
  (ticket_id: string) => (s: { [FEATURE_KEY]: VNSCoreState }) =>
    s[FEATURE_KEY].messages[ticket_id];
