import { Inject, Injectable, OnDestroy } from '@angular/core';
import { CaSubscriber } from '@ca/ca-utils';

import { io, Socket } from 'socket.io-client';
import { UserState } from '../store/reducers/user.reducer';
import { Store } from '@ngrx/store';
import { FEATURE_KEY, VNS_ENVIRONMENT } from '../config';
import {
  CreateResultDTO,
  Message,
  NEW_MESSAGE_TYPE,
  SendMessageWsEvent,
  TICKET_CREATED_TYPE,
  TICKET_STATUS_CHANGED_TYPE,
  TicketStatusChangedSocketEvent,
  TicketStatusChangedSocketEventPayload,
  UserDTOBase,
  VNSEnvironment,
} from '@ca/vns-models';
import { SnackbarService } from '@ca/ca-snackbar';
import {
  NewMessageReceived,
  TicketCreatedEvent,
  TicketStatusChanged,
} from '../store/actions/message.actions';
import { Router } from '@angular/router';
import { RouterReducerState } from '@ngrx/router-store';

@Injectable({
  providedIn: 'root',
})
export class SocketsService implements OnDestroy {
  private socket?: Socket;
  private sub = new CaSubscriber();
  private currentUrl = '/';

  get isConnected() {
    return this.socket?.connected ?? false;
  }

  user?: UserDTOBase;

  constructor(
    private store: Store<{ [FEATURE_KEY]: { user: UserState }; router: RouterReducerState }>,
    @Inject(VNS_ENVIRONMENT) private env: VNSEnvironment,
    private readonly snackbar: SnackbarService,
    private router: Router
  ) {
    this.sub.subscribe(
      this.store.select((s) => s[FEATURE_KEY].user.user),
      { next: (u) => (this.user = u) }
    );
    this.sub.subscribe(
      this.store.select((s) => s.router?.state?.url),
      {
        next: (url) => {
          console.log('current url', url);
          this.currentUrl = url;
        },
      }
    );
  }

  connect(token: string) {
    if (!token) throw new Error('Can not connect with unauthorized user.');
    console.log('connecting...');
    if (!this.isConnected) {
      this.socket = io(this.env.backendUrl, {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });

      this.socket.on('connect', () => {
        console.log('connected');
        if (this.socket?.id) this.getMessages();
      });

      this.socket.on('disconnect', () => {
        console.log('disconnected');
      });
      this.socket.on('reconnect', (attempt) => {
        console.log('reconnected after ' + attempt + 'attempts');
      });

      this.socket.on('error', (e) => {
        console.error('socket error', e);
      });
    }
  }

  disconnect() {
    this.detachHandlers();
    this.socket?.disconnect();
  }

  ngOnDestroy(): void {
    this.detachHandlers();
    this.socket?.disconnect();
  }

  //#region Messages
  sendMessage(message: SendMessageWsEvent) {
    if (this.socket) {
      console.log('emitting from backoffice', message);
      this.socket.emit(NEW_MESSAGE_TYPE, message);
    } else console.error('No socket connection');
  }

  getMessages() {
    this.attachHandlers();
  }
  //#endregion

  //#region Handlers
  private attachHandlers() {
    const s = this.socket;
    if (!s) throw new Error('No socket defined');
    console.log('attaching listener');
    if (s.hasListeners(NEW_MESSAGE_TYPE) === false)
      s.on(NEW_MESSAGE_TYPE, (message) => this.newMessageHandler(message));
    if (s.hasListeners(TICKET_CREATED_TYPE) === false)
      s.on(TICKET_CREATED_TYPE, (payload) => this.ticketCreatedHandler(payload));
    if (s.hasListeners(TICKET_STATUS_CHANGED_TYPE) === false) {
      s.on(TICKET_STATUS_CHANGED_TYPE, (payload) =>
        this.ticketStatusChangedHandler(payload.payload)
      );
    }
  }

  private detachHandlers() {
    if (this.socket) console.log('detaching listeners');
    this.socket?.off(NEW_MESSAGE_TYPE, (message) => this.newMessageHandler(message));
    this.socket?.off(TICKET_CREATED_TYPE, (payload) => this.ticketCreatedHandler(payload));
    this.socket?.off(TICKET_STATUS_CHANGED_TYPE, (payload) =>
      this.ticketStatusChangedHandler(payload)
    );
  }

  private newMessageHandler(message: Message) {
    console.log('message received', message);
    if (this.currentUrl !== `/mijn-dossier/${message.ticket_id}` && this.user?.role === 'member')
      this.snackbar.infoSnackbar(`Je hebt een nieuw bericht ontvangen`, [
        { text: 'Ga', routerLink: ['/mijn-dossier', message.ticket_id] },
      ]);
    else if (this.currentUrl !== `/dossiers/${message.ticket_id}` && this.user?.role !== 'member')
      this.snackbar.infoSnackbar(`Je hebt een nieuw bericht ontvangen`, [
        { text: 'Ga', routerLink: ['/dossiers', message.ticket_id] },
      ]);
    this.store.dispatch(NewMessageReceived({ message }));
  }

  private ticketCreatedHandler(payload: CreateResultDTO) {
    console.log('ticket created', payload);
    this.store.dispatch(TicketCreatedEvent({ payload }));

    if (this.user?.role === 'admin')
      this.snackbar.infoSnackbar('Er is een nieuw dossier beschikbaar', [
        {
          text: 'Ga',
          routerLink: ['dossiers', payload.id],
        },
      ]);
    else {
      this.router.navigate(['dossiers', payload.id]);
    }
  }
  private ticketStatusChangedHandler(payload: TicketStatusChangedSocketEventPayload) {
    console.log('ticket status changed', payload);
    this.store.dispatch(TicketStatusChanged({ payload }));
  }
  //#endregion
}
