import { UserDTO, UserDTOBase, UserRole } from './auth.models';
import { Message } from './back-office.models';
export interface Ticket {
  id: string;
  title: string;
  requested_at: string; // DateTime           @default(now())
  updated_at: string; //    DateTime?          @updatedAt()
  status: TicketStatus;
  third_parties?: UserDTO[];
  attachments: TicketAttachment[];
  messages: Message[];
  users: UserDTO[];
  metaUrl: string;
}

export interface TicketListItemDTO {
  id: string;
  title: string;
  requested_at: string; // DateTime           @default(now())
  updated_at: string; //    DateTime?          @updatedAt()
  status: TicketStatus;
}

export interface TicketDTO {
  id: string;

  title: string;
  status: TicketStatus;

  requested_at: Date;
  updated_at?: Date;

  messages: Message[];
  attachments: TicketAttachment[];
  users: UserDTO[];
  metaUrl?: string;
}

export interface TicketAttachment {
  // date_added, file_extension, file_mime_type, file_name, file_original_name,file_size_bytes,id, sent_by_id, sent_by_name, sent_by_type, ticket_id
  id: string;
  // download_url: string;

  file_extension: string;
  file_mime_type: string;
  file_name: string;
  file_original_name: string;
  file_size_bytes: number;

  date_added: Date;

  ticket_id: string;
  sent_by_id: string;
  sent_by_name: string;
  sent_by_type: UserRole;
}

export type TicketStatus =
  | 'Open'
  | 'WaitingForVNS'
  | 'WaitingForThirdParty'
  | 'WaitingForClient'
  | 'Closed';
