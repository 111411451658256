import { TicketListItemDTO, Ticket } from '@ca/vns-models';
import { createAction, props } from '@ngrx/store';
import { TicketStatus } from '@prisma/client';
import { SetStatusDialogData } from '../../components/set-status-dialog/set-status-dialog.component';

export enum TicketActions {
  loadTickets = '[Tickets] load',
  loadTicketsSuccess = '[Tickets] successfully loaded',
  loadTicketsFailure = '[Tickets] failed to load',

  loadTicketDetail = '[Ticket Detail] load',
  loadTicketDetailSuccess = '[Ticket Detail] successfully loaded',
  loadTicketDetailFailure = '[Ticket Detail] failed to load',

  setTicketStatus = '[Ticket Status] set',
  setTicketStatusSuccess = '[Ticket Status] set SUCCESS',
  setTicketStatusFailure = '[Ticket Status] set FAILURE',

  openSetStatusDialog = '[Ticket Status Dialog] open',
}

export const LoadTickets = createAction(TicketActions.loadTickets);
export const LoadTicketsSuccess = createAction(
  TicketActions.loadTicketsSuccess,
  props<{ tickets: TicketListItemDTO[] }>()
);
export const LoadTicketsFailure = createAction(
  TicketActions.loadTicketsFailure,
  props<{ error: any }>()
);

export const LoadTicketDetail = createAction(
  TicketActions.loadTicketDetail,
  props<{ id: string }>()
);
export const LoadTicketDetailSuccess = createAction(
  TicketActions.loadTicketDetailSuccess,
  props<{ ticket: Ticket }>()
);
export const LoadTicketDetailFailure = createAction(
  TicketActions.loadTicketDetailFailure,
  props<{ error: any }>()
);

export const openSetTicketStatusDialog = createAction(
  TicketActions.openSetStatusDialog,
  props<SetStatusDialogData>()
);
export const SetTicketStatus = createAction(
  TicketActions.setTicketStatus,
  props<{ id: string; status: TicketStatus }>()
);
export const SetTicketStatusSuccess = createAction(
  TicketActions.setTicketStatusSuccess,
  props<{ id: string; status: TicketStatus }>()
);
export const SetTicketStatusFailure = createAction(
  TicketActions.setTicketStatusFailure,
  props<{ id: string; status: TicketStatus }>()
);
