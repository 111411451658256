import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  LoadMessagesForContext,
  SendAttachment,
  SendMessage,
} from '../../store/actions/message.actions';
import { Store } from '@ngrx/store';
import { FEATURE_KEY } from '../../config';
import { selectMessagesForContext, VNSCoreState } from '../../store';
import { Observable } from 'rxjs';
import { Message, UserRole } from '@ca/vns-models';
import { DateTime } from 'luxon';
import { SocketsService } from '../../services/sockets.service';

@Component({
  selector: 'ca-vns-chat',
  template: `<div id="ca-chat-conversation">
    <ng-container *ngIf="_context?.user?.id as ownerId">
      <div class="messages">
        <ca-message
          *ngFor="let msg of messages$ | async"
          [direction]="msg.sent_by_id === ownerId ? 'outgoing' : 'incoming'"
          [message]="msg"></ca-message>
      </div>
      <!-- TODO: also set fileOver to false on error -->
      <div
        id="chat-ui-form"
        [class.fileOver]="fileOver"
        (dragenter)="fileOver = true"
        (dragleave)="fileOver = false">
        <form #form hidden (change)="uploadForm(form)">
          <input type="file" name="files" multiple hidden #fileUpload />
          <!-- (change)="uploadFile($event)" -->
        </form>
        <mat-form-field *ngIf="!fileOver" (dragenter)="fileOver = true" appearance="outline">
          <input name="message" (keyup.enter)="send()" matInput [(ngModel)]="message" type="text" />

          <button mat-icon-button matSuffix (click)="fileUpload.click()" [disabled]="!connected">
            <mat-icon>upload</mat-icon>
          </button>

          <button mat-icon-button matSuffix (click)="send()" [disabled]="!connected">
            <mat-icon>send</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </ng-container>
  </div>`,
  styles: [
    `
      .messages {
        min-height: 8rem;
        display: flex;
        flex-direction: column;
        padding: 0.33rem;
        ca-message {
          width: fit-content;
        }
        ca-message[ng-reflect-direction='incoming'] {
          align-self: flex-start;
        }
        ca-message[ng-reflect-direction='outgoing'] {
          align-self: flex-end;
        }
      }
      #ca-chat-conversation {
        height: fit-content;
        margin: 0.66rem;
        padding: 0 0.66rem;
        border-radius: 0.66rem;
        border: 1px solid #4d30f2;
      }
      #chat-ui-form {
        padding: 0 1.33rem;
        width: 100%;
        min-height: 5rem;
      }
      #chat-ui-form.fileOver {
        background-color: #4d30f2;
      }
      mat-form-field {
        width: calc(100% - 2.66rem);
      }
    `,
  ],
  standalone: false,
})
export class VnsChatComponent implements OnInit {
  @Input() set context(context: VNSChatContext) {
    this._context = context;
    console.log('context changed', context);
    if (context) {
      this.store.dispatch(LoadMessagesForContext({ ticket_id: this._context.ticket_id }));
      this.messages$ = this.store.select(selectMessagesForContext(this._context.ticket_id));
    }
  }

  get connected() {
    return this.socket.isConnected;
  }
  fileOver = false;
  _context?: VNSChatContext;
  message: string | null = null;
  messages$?: Observable<Message[] | undefined>;

  constructor(
    private store: Store<{ [FEATURE_KEY]: VNSCoreState }>,
    private socket: SocketsService
  ) {}

  ngOnDestroy(): void {
    // TODO: cleanup
    console.log('DO CLEANUP');
  }

  ngOnInit(): void {
    if (!this._context) throw new Error('Context must be provided as input!');
    // this.store.dispatch(LoadMessagesForContext({ ticket_id: this._context.ticket_id }));
    this.messages$ = this.store.select(selectMessagesForContext(this._context.ticket_id));
  }

  uploadForm(form: HTMLFormElement) {
    if (!this._context) return;
    const formData = new FormData(form);
    console.log(formData);
    console.log(form.dataset);
    this.store.dispatch(SendAttachment({ formData, context: this._context }));
  }

  send() {
    // if (!this.client_id) throw new Error('Client ID was not defined!');
    // if (!this.user) throw new Error('User was not defined!');
    if (!this._context) throw new Error('Context is not defined');
    const text = this.message;
    if (text && text.length > 0) {
      console.log(`sending message: ${this.message}`);
      this.store.dispatch(
        SendMessage({
          message: {
            message: text,
            sent_at: DateTime.now().toJSDate(),
            sent_by_id: this._context?.user.id,
            sent_by_name: this._context.user.name,
            sent_by_type: this._context.user.role,
            ticket_id: this._context.ticket_id,
            third_party: false,
          },
        })
      );
      this.message = null;
    }
  }
}
export type VNSChatContext = {
  ticket_id: string;
  user: { id: string; name: string; role: UserRole };
};

export interface ChatMessage {
  id: string;
  message: string;

  sent_at: Date;
  sent_by_id: string;
  sent_by_name: string;
  sent_by_type: string;
}
