import { IUserProfile } from '../interfaces/api';

export type LoginSuccess = {
  user?: any;
  profile?: IUserProfile;
  avatar?: string;
  token?: string;
  redirectTo?: string;
  refreshToken?: string;
};
