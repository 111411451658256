import { Inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { firstValueFrom, switchMap } from 'rxjs';
import {
  Login,
  LoginAdminSuccess,
  LoginMemberSuccess,
  LoginFailure,
  LogoutSuccess,
  Logout,
  LogoutFailure,
  RefreshToken,
  RefreshTokenSuccess,
  RefreshTokenFailure,
  UnauthorizedPageLoad,
  UnauthorizedHttpRequest,
  ResetPassword,
  RequestNewPassword,
  RequestNewPasswordSuccess,
  RequestNewPasswordFailure,
  ResetPasswordFailure,
  ResetPasswordSuccess,
  LoadUserProfileSuccess,
} from '../reducers/user.reducer';
import { AuthService } from '../../services/auth.service';
import { CaSubscriber } from '@ca/ca-utils';
import { SignInResultDTO, VNSEnvironment } from '@ca/vns-models';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { VNS_ENVIRONMENT } from '../../config';
import { VNSCoreConfig } from '../../models/config.models';
import { SocketsService } from '../../services/sockets.service';

// https://www.elvisduru.com/blog/nestjs-jwt-authentication-refresh-token
@Injectable()
export class LoginEffects implements OnDestroy {
  private sub = new CaSubscriber();
  constructor(
    @Inject(VNS_ENVIRONMENT)
    private readonly env: VNSEnvironment,
    @Inject(VNSCoreConfig) private readonly config: VNSCoreConfig,
    private readonly actions$: Actions,
    private auth: AuthService,
    private router: Router,
    private readonly ws: SocketsService,
    private store: Store
  ) {
    this.sub.subscribe(
      this.actions$.pipe(ofType(LoginAdminSuccess, LoginMemberSuccess, LoadUserProfileSuccess)),
      this.loginObserver
    );
    this.sub.subscribe(
      this.actions$.pipe(ofType(LogoutSuccess, UnauthorizedPageLoad, UnauthorizedHttpRequest)),
      this.logoutObserver
    );
    this.sub.subscribe(this.actions$.pipe(ofType(RefreshTokenSuccess)), this.refreshObserver);
    this.sub.subscribe(this.actions$.pipe(ofType(Logout)), this.logoutObserver);

    if (this.auth.hasBearerToken) {
      this.auth.validateAccessToken().then((res) => {
        if (res === false) this.router.navigate(['/login']);
      });
    }
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
    this.ws.disconnect();
  }

  onLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Login),
      switchMap((dto) =>
        firstValueFrom(this.auth.login(dto))
          .then((res) =>
            this.config.role === 'admin'
              ? LoginAdminSuccess(res as SignInResultDTO)
              : LoginMemberSuccess(res as SignInResultDTO)
          )
          .catch((error) => LoginFailure({ error }))
      )
    )
  );

  onLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Logout),
      switchMap(() =>
        this.auth
          .logoutRequest()
          .then((res) => LogoutSuccess())
          .catch((err) => LogoutFailure({ error: err }))
      )
    )
  );

  onRefresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RefreshToken),
      switchMap(() =>
        this.auth
          .refreshToken()
          .then((res) => RefreshTokenSuccess(res))
          .catch((err) => RefreshTokenFailure({ error: err }))
      )
    )
  );

  onResetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RequestNewPassword),
      switchMap(({ email }) =>
        this.auth
          .resetPassword(email)
          .then(() => RequestNewPasswordSuccess())
          .catch((error) => RequestNewPasswordFailure({ error }))
      )
    )
  );

  onSetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResetPassword),
      switchMap(({ code, password }) =>
        this.auth
          .setPassword(code, password)
          .then(() => ResetPasswordSuccess())
          .catch((error) => ResetPasswordFailure({ error }))
      )
    )
  );

  // onUnauthorized$ = createEffect(() => this.actions$.pipe(ofType()))

  private logoutObserver = {
    next: () => {
      // clear bearer token
      sessionStorage.removeItem(this.env.sessionStorageBearerKey);
      console.log('NAVIGATING TO LOGIN');
      this.auth._logout();
      this.ws.disconnect();
      // don't do this if route is login
      this.router.navigate(['login']);
    },
  };

  /**
   * Sets bearer token in session storage and redirects to success route.
   */
  private loginObserver = {
    next: async (res: SignInResultDTO) => {
      if (res.access_token) this.auth.setAccessToken(res.access_token);
      if (res.refresh_token) this.auth.setRefreshToken(res.refresh_token);
      if (res.access_token && !this.ws.isConnected) {
        this.ws.connect(res.access_token);
      }
      this.router.navigate(this.config.role === 'admin' ? ['/dossiers'] : ['/mijn-dossier']);
    },
  };

  private refreshObserver = {
    next: async (res: { access_token: string; refresh_token: string }) => {
      if (res.access_token) {
        console.log('Refreshed token successfully', moment().toISOString());
        this.auth.setAccessToken(res.access_token);
        this.auth.setRefreshToken(res.refresh_token);
      }
    },
  };
}
