import { UserRole } from './auth.models';

export interface CreateFileAttachmentArgs {
  file_extension: string;
  file_mime_type: string; //file.mimetype,
  file_name: string; //file.filename,
  file_original_name: string; // file.originalname,
  file_size_bytes: number;

  sent_by_id: string; // user.sub,
  sent_by_name: string; //user.name,
  sent_by_type: UserRole;

  ticket_id: string; // ticket_id,
}
