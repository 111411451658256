import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  StripeCardComponent,
  StripeCardNumberComponent,
  StripePaymentElementComponent,
} from 'ngx-stripe';

//#region config
import { appRoutes } from './app.routes';
import { CA_ENVIRONMENT, VATCheckService } from '@ca/ca-ng-core';
import { environment } from '../environments/environment';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
//#endregion

//#region Components
import { AppComponent } from './app.component';
import { NewsArticleComponent } from './pages/news-article/news-article.component';
import { RegistrationFormComponent } from './components/registration-form/registration-form.component';
import { PublicPageComponent } from './pages/public-page/public-page.component';
import { NewsArticleSummaryComponent } from './components/news-article-summary/news-article-summary.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { TicketDetailComponent } from './pages/ticket-detail/ticket-detail.component';
import { AccordeonPluginComponent } from './components/accordeon-plugin/accordeon-plugin.component';
import { NewTicketFormComponent } from './components/new-ticket-form/new-ticket-form.component';
import { NewsGalleryComponent } from './components/news-gallery/news-gallery.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { MyFileComponent } from './pages/my-file/my-file.component';
import { CtaComponent } from './components/cta/cta.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
//#endregion

//#region Services
import { RegistrationsService } from './services/registrations.service';
import { CmsService } from './services/cms.service';
import { BreadcrumbService } from './services/breadcrumb.service';
//#endregion

//#region MAT
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCommonModule } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { SnackbarModule, SnackbarServiceConfiguration } from '@ca/ca-snackbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
//#endregion

//#region modules
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ROOT_REDUCER, metaReducers } from './store';
import { MyFileEffects } from './store/effects/my-file.effects';
import { ChatUiModule } from '@ca/chat-ui';
import { VNS_ENVIRONMENT, VNSCoreConfig, VnsNgCoreModule } from '@ca/vns-ng-core';
import { NgxStripeModule } from 'ngx-stripe';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
//#endregion

//#region Effects
import { AppEffects } from './store/effects/app.effects';
import { PagesEffects } from './store/effects/pages.effects';
import { RegistrationEffects } from './store/effects/registration.effects';
import { MijnVnsComponent } from './components/mijn-vns/mijn-vns.component';
//#endregion

export function initializeApp(appInitService: CmsService) {
  return (): Promise<unknown> => {
    return appInitService.init();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    NewsArticleComponent,
    RegistrationFormComponent,
    PublicPageComponent,
    NewsArticleComponent,
    NewsArticleSummaryComponent,
    NewsGalleryComponent,
    BreadcrumbComponent,
    ContactFormComponent,
    MyFileComponent,
    TicketDetailComponent,
    AccordeonPluginComponent,
    NewTicketFormComponent,
    CtaComponent,
    PageNotFoundComponent,
    MijnVnsComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    RouterModule.forRoot(appRoutes, {
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
    StoreModule.forRoot(ROOT_REDUCER, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([AppEffects, PagesEffects, RegistrationEffects, MyFileEffects]),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
    }),
    StoreDevtoolsModule.instrument({ connectInZone: true }),
    FormsModule,
    ReactiveFormsModule,
    // https://www.google.com/recaptcha/admin/site/705119406
    // https://rodrigokamada.medium.com/adding-the-google-recaptcha-v3-to-an-angular-application-3ea24c2a05e6
    // https://www.npmjs.com/package/ng-recaptcha?activeTab=readme#example-basic-v3
    // https://github.com/DethAriel/ng-recaptcha?tab=readme-ov-file#readme
    // RecaptchaV3Module,
    VnsNgCoreModule.forRoot(new VNSCoreConfig('member')),
    SnackbarModule.forRoot({
      service: new SnackbarServiceConfiguration(),
    }),
    ChatUiModule,
    //#region MAT
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatCommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule,
    MatListModule,
    MatStepperModule,
    //#endregion
    StripePaymentElementComponent,
    StripeCardComponent,
    StripeCardNumberComponent,
    NgxStripeModule.forRoot(environment.stripePublicKey, {
      locale: 'nl',
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [CmsService],
    },
    { provide: CA_ENVIRONMENT, useValue: environment },
    { provide: VNS_ENVIRONMENT, useValue: environment },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaSiteKey,
    },
    // { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
    CmsService,
    VATCheckService,
    RegistrationsService,
    BreadcrumbService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
