import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

import { OnboardingService } from '../../services';
import {
  SetPasswordActionData,
  ISetPasswordApiResponse,
  OnboardingErrorCode,
  OnboardingModuleConfiguration,
  IResetPasswordApiResponse,
  OnboardingError,
  ResetPasswordRequest,
  OnboardingState,
} from '../../types';
import {
  setPasswordSuccess,
  setPasswordFailure,
  resetPasswordFailure,
  resetPasswordSuccess,
} from '../actions';
import { OnboardingAction } from '../config';
import { CreateOnboardingError, isOnboardingError } from '../utils';
import { OnboardingEffects } from './onboarding.effects';
import { SnackbarService } from '@ca/ca-snackbar';
import { LoggingService } from '@ca/ca-ng-core';
import { Store } from '@ngrx/store';

@Injectable()
export class PasswordEffects extends OnboardingEffects implements OnDestroy {
  private resetPasswordObserver = {
    next: () => {
      this.router.navigate([this.config.appRoutes.resetPassword]);
    },
  };

  private resetPasswordResultObserver = {
    next: (res: IResetPasswordApiResponse | OnboardingError) => {
      if (isOnboardingError(res)) this.store.dispatch(this.QueueError(res.message));
      else {
        this.store.dispatch(this.QueueSuccess(this.config.messages.setPassword.updated));
        this.router.navigate([this.config.appRoutes.login]);
      }
    },
  };

  private setPasswordObserver = {
    next: (res: ISetPasswordApiResponse | OnboardingError) => {
      if (isOnboardingError(res)) this.store.dispatch(this.QueueError(res.message));
      else {
        this.store.dispatch(this.QueueSuccess(this.config.messages.setPassword.updated));
        this.router.navigate([this.config.appRoutes.login]);
      }
    },
  };

  onResetPassword$ = this.config.disableEffects
    ? null
    : createEffect(() =>
        this.actions$.pipe(
          ofType(OnboardingAction.RESET_PASSWORD_REQUEST),
          switchMap((req: ResetPasswordRequest) =>
            this.svc.resetPassword(req.data).pipe(
              map((res: IResetPasswordApiResponse) => {
                if (res.success) {
                  this.store.dispatch(
                    this.QueueSuccess(this.config.messages.resetPassword.link_sent)
                  );
                  this.router.navigate([this.config.appRoutes.login]);

                  return resetPasswordSuccess({
                    success: res.success,
                  });
                } else
                  return resetPasswordFailure(
                    CreateOnboardingError(
                      OnboardingErrorCode.RESET_PASSWORD_REQUEST_FAILED,
                      'Could not reset password.'
                    )
                  );
              }),
              catchError(() =>
                of(
                  resetPasswordFailure(
                    CreateOnboardingError(
                      OnboardingErrorCode.RESET_PASSWORD_REQUEST_FAILED,
                      'Could not reset password.'
                    )
                  )
                )
              )
            )
          )
        )
      );

  onSetPassword$ = this.config.disableEffects
    ? null
    : createEffect(() =>
        this.actions$.pipe(
          ofType(OnboardingAction.SET_PASSWORD_REQUEST),
          switchMap((res: SetPasswordActionData) =>
            this.svc.setPassword(res.data).pipe(
              map((res: ISetPasswordApiResponse) => {
                if (res.status == 'updated') return setPasswordSuccess();
                else
                  return setPasswordFailure(
                    CreateOnboardingError(
                      OnboardingErrorCode.SET_PASSWORD_REQUEST_FAILED,
                      'Could not set password...'
                    )
                  );
              }),
              catchError((err: any) =>
                of(
                  setPasswordFailure(
                    CreateOnboardingError(
                      OnboardingErrorCode.SET_PASSWORD_REQUEST_FAILED,
                      'Could not set password...'
                    )
                  )
                )
              )
            )
          )
        )
      );
  constructor(
    protected override config: OnboardingModuleConfiguration,
    protected override actions$: Actions,
    protected override router: Router,
    protected override svc: OnboardingService,
    protected override snackbar: SnackbarService,
    protected override logger: LoggingService,
    protected override store: Store<{ onboarding: OnboardingState }>
  ) {
    super(config, svc, actions$, router, snackbar, logger, store);

    this.observeResetPassword();

    this.observeSetPassword();
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  private observeResetPassword() {
    this.sub.subscribe(
      this.actions$.pipe(ofType(OnboardingAction.RESET_PASSWORD)),
      this.resetPasswordObserver
    );
    this.sub.subscribe(
      this.actions$.pipe(
        ofType(OnboardingAction.RESET_PASSWORD_SUCCESS, OnboardingAction.RESET_PASSWORD_FAILURE)
      ),
      this.resetPasswordResultObserver
    );
  }

  private observeSetPassword() {
    this.sub.subscribe(
      this.actions$.pipe(
        ofType(OnboardingAction.SET_PASSWORD_SUCCESS, OnboardingAction.SET_PASSWORD_FAILURE)
      ),
      this.setPasswordObserver
    );
  }
}
